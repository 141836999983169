import React from 'react';

const PageFiveERP = () => {
  const styles = {
    body: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: '#121212', // Dark mode background
      color: '#ffffff',
      padding: 0,
      margin: 0,
    },
    container: {
      padding: '50px 15px',
    },
    headerSpan: {
      color: '#f97316',
      fontSize: '14px',
      fontWeight: 700,
      textAlign: 'center',
      display: 'block',
      marginBottom: '10px',
    },
    headerH1: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: 1.3,
      textAlign: 'center',
      marginBottom: '30px',
    },
    serviceCard: {
      backgroundColor: '#1e1e1e', // Dark card background
      borderRadius: '12px',
      padding: '20px',
      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      margin: '0 10%', // 10% margin on left and right
      maxWidth: '80%', // Restrict card size
    },
    serviceCardHover: {
      transform: 'translateY(-10px)',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
    },
    icon: {
      fontSize: '40px',
      color: '#f97316',
    },
    serviceCardH3: {
      fontSize: '20px',
      fontWeight: 700,
      margin: '20px 0 10px',
      textAlign: 'center',
      color: '#ffffff',
    },
    serviceCardP: {
      fontSize: '14px',
      lineHeight: 1.6,
      color: '#b0b0b0',
      textAlign: 'justify',
    },
    highlightIcon: {
      fontSize: '14px',
      color: '#f97316',
      marginRight: '10px',
    },
    highlightSpan: {
      backgroundColor: '#f97316',
      color: '#ffffff',
      padding: '5px 10px',
      borderRadius: '20px',
      fontSize: '12px',
    },
    arrowIcon: {
      fontSize: '20px',
      color: '#f97316',
    },
  };

  const serviceData = [
    {
        icon: 'fas fa-cogs',
        title: 'Custom ERP System Development',
        description:
          'Create a fully tailored ERP system to streamline your business operations. We develop customized ERP solutions that integrate key business functions such as finance, HR, inventory, and supply chain, helping your business run efficiently and effectively.',
        highlights: [
          'Tailored ERP Solutions',
          'Streamlined Business Processes',
          'Centralized Data Management',
          'Scalable and Flexible Architecture',
        ],
      },
      {
        icon: 'fas fa-sync-alt',
        title: 'ERP System Integration',
        description:
          'Integrate your existing systems and tools with a powerful ERP solution. We ensure seamless data flow between your ERP system and other software, such as CRM, e-commerce platforms, and third-party tools, for a unified approach to managing your business.',
        highlights: [
          'Seamless System Integration',
          'Data Synchronization',
          'Third-Party Software Compatibility',
          'Real-Time Data Access',
        ],
      },
      {
        icon: 'fas fa-users',
        title: 'Human Resource Management (HRM) Module',
        description:
          'Simplify HR tasks with an integrated Human Resource Management (HRM) module. We develop tools for managing employee records, payroll, recruitment, performance evaluation, and attendance, ensuring a smooth HR process and improved employee engagement.',
        highlights: [
          'Employee Management and Records',
          'Payroll Automation',
          'Recruitment and Onboarding',
          'Employee Performance Tracking',
        ],
      },
      {
        icon: 'fas fa-chart-line',
        title: 'Financial Management Module',
        description:
          'Take control of your business finances with a comprehensive ERP financial management module. We build tools for managing accounting, budgeting, financial reporting, and tax compliance, making it easier to monitor and optimize your financial performance.',
        highlights: [
          'Accounting and Bookkeeping',
          'Automated Financial Reporting',
          'Budgeting and Forecasting',
          'Tax Management and Compliance',
        ],
      },
      {
        icon: 'fas fa-box',
        title: 'Inventory and Supply Chain Management',
        description:
          'Optimize your inventory and supply chain processes with our ERP solutions. We help you track stock levels, manage procurement, streamline order fulfillment, and gain real-time visibility into your supply chain operations.',
        highlights: [
          'Real-Time Inventory Tracking',
          'Automated Order Management',
          'Supplier and Procurement Management',
          'Supply Chain Analytics',
        ],
      },
      {
        icon: 'fas fa-store',
        title: 'Sales and Customer Relationship Management (CRM)',
        description:
          'Drive sales and improve customer relationships with an integrated CRM module. We provide tools for managing customer interactions, sales pipelines, order processing, and reporting, ensuring your sales team is efficient and customer-focused.',
        highlights: [
          'Lead and Opportunity Management',
          'Sales Performance Tracking',
          'Customer Support and Service',
          'Detailed Reporting and Analytics',
        ],
      },
      {
        icon: 'fas fa-truck',
        title: 'Manufacturing and Production Management',
        description:
          'Manage your manufacturing and production processes more effectively with ERP solutions that automate workflows, track raw materials, and optimize production schedules, helping you reduce costs and improve productivity.',
        highlights: [
          'Production Planning and Scheduling',
          'Raw Material Management',
          'Production Workflow Automation',
          'Real-Time Manufacturing Data',
        ],
      },
      {
        icon: 'fas fa-lock',
        title: 'ERP Security and Access Control',
        description:
          'Ensure your ERP system is secure with advanced security and access control features. We implement role-based access, data encryption, and multi-factor authentication to protect sensitive business data and ensure regulatory compliance.',
        highlights: [
          'Role-Based Access Control',
          'Data Encryption and Security',
          'Multi-Factor Authentication',
          'Compliance with Industry Standards',
        ],
      },
      {
        icon: 'fas fa-cloud',
        title: 'Cloud ERP Solutions',
        description:
          'Take advantage of the cloud to run your ERP system more efficiently. We provide cloud-based ERP solutions that ensure scalability, flexibility, and remote access, so your business can operate from anywhere, at any time.',
        highlights: [
          'Cloud-Based ERP Deployment',
          'Remote Access and Flexibility',
          'Scalability and Cost Efficiency',
          'Automatic Updates and Backups',
        ],
      },
      {
        icon: 'fas fa-chart-pie',
        title: 'ERP Analytics and Reporting',
        description:
          'Make data-driven decisions with powerful ERP analytics and reporting tools. We develop advanced reporting dashboards that provide real-time insights into your business performance, helping you identify trends, monitor KPIs, and optimize processes.',
        highlights: [
          'Real-Time Business Insights',
          'Customizable Reporting Dashboards',
          'Data-Driven Decision Making',
          'KPIs and Performance Monitoring',
        ],
      }
      
      
  ];

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div>
          <span style={styles.headerSpan}>SERVICES</span>
          <h1 style={styles.headerH1}>
            Our Expertise In ERP Development
          </h1>
        </div>
        <div className="row g-4">
          {serviceData.map((service, index) => (
            <div className="col-12 col-md-6 col-lg-4" key={index}>
              <div
                style={styles.serviceCard}
                onMouseOver={(e) =>
                  Object.assign(e.currentTarget.style, styles.serviceCardHover)
                }
                onMouseOut={(e) =>
                  Object.assign(e.currentTarget.style, {
                    transform: 'translateY(0)',
                    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                  })
                }
              >
                <div className="text-center mb-3" style={styles.icon}>
                  <i className={service.icon}></i>
                </div>
                <h3 style={styles.serviceCardH3}>{service.title}</h3>
                <p style={styles.serviceCardP}>{service.description}</p>
                <div>
                  {service.highlights.map((highlight, idx) => (
                    <div key={idx} className="d-flex align-items-center mb-2">
                      <i
                        className="fas fa-check"
                        style={styles.highlightIcon}
                      ></i>
                      <span style={styles.highlightSpan}>{highlight}</span>
                    </div>
                  ))}
                </div>
                <div className="text-end">
                  <i className="fas fa-arrow-right" style={styles.arrowIcon}></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PageFiveERP;
