import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faHandshake, faFileInvoiceDollar, faShieldAlt, faBullseye, faFileContract, faStar, faCoins, faUserFriends } from '@fortawesome/free-solid-svg-icons';

const EightStartup = () => {
  return (
    <div style={{ backgroundColor: '#121212', minHeight: '100vh', padding: '20px 0' }}>
      <div style={{ maxWidth: '1000px', margin: '0 auto', padding: '20px 10px', textAlign: 'center' }}>
        <div style={{ marginBottom: '30px' }}>
          <span
            style={{
              color: '#ff6600',
              fontWeight: '600',
              fontSize: '16px',
              letterSpacing: '0.5px',
              textTransform: 'uppercase',
              animation: 'fadeIn 2s ease-in-out',
            }}
          >
            WHO WE ARE
          </span>
          <h1
            style={{
              fontSize: '28px',
              fontWeight: '500',
              color: '#fff',
              animation: 'fadeInUp 2s ease-in-out',
            }}
          >
            Built Relationships with 1100+ Happy Clients!
          </h1>
          <p
            style={{
              fontSize: '14px',
              color: '#bbb',
              maxWidth: '700px',
              margin: '0 auto',
              fontWeight: '300',
              animation: 'fadeInUp 3s ease-in-out',
            }}
          >
            Companies employ software developers from us because we have a proven track record of delivering high-quality projects on time.
          </p>
        </div>

        {/* First Row (Image on right, 3 points of text on left) */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px', alignItems: 'center' }}>
          <div style={{ width: '48%' }}>
            <img
              alt="Group of people smiling"
              src="https://images.pexels.com/photos/5668859/pexels-photo-5668859.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              style={{
                width: '100%',
                borderRadius: '8px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                transition: 'transform 0.3s ease',
              }}
            />
          </div>
          <div style={{ width: '48%', textAlign: 'left' }}>
            {[ 
              { icon: faAward, text: "8+ Years of Average Experience" },
              { icon: faHandshake, text: "Integrity & Transparency" },
              { icon: faFileInvoiceDollar, text: "FREE No Obligation Quote" },
            ].map((feature, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '15px',
                  backgroundColor: '#1e1e1e',
                  padding: '12px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  animation: 'fadeInUp 1.5s ease-in-out',
                }}
              >
                <FontAwesomeIcon
                  icon={feature.icon}
                  style={{
                    fontSize: '30px',
                    color: '#ff6600',
                    marginRight: '15px',
                    transition: 'transform 0.3s ease',
                  }}
                />
                <p
                  style={{
                    fontSize: '14px',
                    color: '#fff',
                    fontWeight: '500',
                    margin: '0',
                  }}
                >
                  {feature.text}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Second Row (Image on left, Text on right) */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px', alignItems: 'center' }}>
          <div style={{ width: '48%', textAlign: 'left' }}>
            {[ 
              { icon: faShieldAlt, text: "Information Security" },
              { icon: faBullseye, text: "Outcome-Focused Approach" },
              { icon: faFileContract, text: "Transparency is Guaranteed" },
            ].map((feature, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '15px',
                  backgroundColor: '#1e1e1e',
                  padding: '12px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  animation: 'fadeInUp 1.5s ease-in-out',
                }}
              >
                <FontAwesomeIcon
                  icon={feature.icon}
                  style={{
                    fontSize: '30px',
                    color: '#ff6600',
                    marginRight: '15px',
                    transition: 'transform 0.3s ease',
                  }}
                />
                <p
                  style={{
                    fontSize: '14px',
                    color: '#fff',
                    fontWeight: '500',
                    margin: '0',
                  }}
                >
                  {feature.text}
                </p>
              </div>
            ))}
          </div>
          <div style={{ width: '48%' }}>
            <img
              alt="People working together"
              src="https://images.pexels.com/photos/5668842/pexels-photo-5668842.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              style={{
                width: '100%',
                borderRadius: '8px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                transition: 'transform 0.3s ease',
              }}
            />
          </div>
        </div>

        {/* Third Row (Image on right, 3 points of text on left) */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px', alignItems: 'center' }}>
          <div style={{ width: '48%' }}>
            <img
              alt="Group of people smiling"
              src="https://images.pexels.com/photos/3823488/pexels-photo-3823488.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              style={{
                width: '100%',
                borderRadius: '8px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                transition: 'transform 0.3s ease',
              }}
            />
          </div>
          <div style={{ width: '48%', textAlign: 'left' }}>
            {[ 
              { icon: faStar, text: "4.8/5 Rating on Clutch" },
              { icon: faCoins, text: "Costs Lower Than Your Local Guy" },
              { icon: faUserFriends, text: "Hire a Team of Your Choice" },
            ].map((feature, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '15px',
                  backgroundColor: '#1e1e1e',
                  padding: '12px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  animation: 'fadeInUp 1.5s ease-in-out',
                }}
              >
                <FontAwesomeIcon
                  icon={feature.icon}
                  style={{
                    fontSize: '30px',
                    color: '#ff6600',
                    marginRight: '15px',
                    transition: 'transform 0.3s ease',
                  }}
                />
                <p
                  style={{
                    fontSize: '14px',
                    color: '#fff',
                    fontWeight: '500',
                    margin: '0',
                  }}
                >
                  {feature.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EightStartup;
