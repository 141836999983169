import React from 'react';

const OurStory = () => {
  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px',
    padding: '20px',
    backgroundColor: '#111111',
  };

  const cardStyle = {
    backgroundColor: 'black',
    border: '1px solid #333333',
    borderRadius: '8px',
    textAlign: 'center',
    padding: '20px',
    width: '100%',
    maxWidth: '300px',
    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
  };

  const iconStyle = {
    backgroundColor: '#f0f0f0',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto 20px',
    transition: 'background-color 0.3s, color 0.3s',
  };

  const iconTextStyle = {
    fontSize: '24px',
    color: '#333',
    transition: 'color 0.3s',
  };

  const headingStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '0 0 10px',
    color: '#f0f0f0',
    transition: 'color 0.3s',
  };

  const descriptionStyle = {
    fontSize: '14px',
    color: '#cfcfcf',
    margin: '0',
    transition: 'color 0.3s',
  };

  
  const cards = [
    {
      icon: 'fas fa-building',
      title: 'ABOUT US',
      description:
        'Kotibox Global Technologies, based in Jaipur, India, specializes in software solutions, offering cutting-edge website and app development to meet business needs. We ensure strategic, innovative solutions for digital projects that enhance user experiences and drive growth.',
    },
    {
      icon: 'fas fa-globe',
      title: 'OUR MISSION',
      description:
        'We aim to lead the digital revolution by creating innovative websites and applications. Our mission is to ensure businesses achieve their goals by harnessing technology to deliver unparalleled success.',
    },
    {
      icon: 'fas fa-users',
      title: 'OUR PROCESS',
      description:
        'Our agile methodology ensures seamless development processes, real-time feedback integration, and exceptional end results. We strive for user-focused solutions tailored to meet every client’s specific requirements.',
    },
    {
      icon: 'fas fa-rocket',
      title: 'OUR VISION',
      description:
        'We envision a world where technology bridges gaps in business. Our goal is to create cutting-edge solutions that empower businesses and set benchmarks in the industry.',
    },
    {
      icon: 'fas fa-laptop-code',
      title: 'WEBSITE DEVELOPMENT',
      description:
        'We craft custom, high-performance websites tailored to specific business requirements. Our web solutions are designed for scalability, functionality, and a seamless user experience.',
    },
    {
      icon: 'fas fa-mobile-alt',
      title: 'MOBILE APP DEVELOPMENT',
      description:
        'Developing intuitive, scalable mobile applications for diverse platforms, we ensure a blend of functionality and style to meet user expectations.',
    },
    {
      icon: 'fas fa-chart-line',
      title: 'DIGITAL MARKETING',
      description:
        'Offering comprehensive digital marketing strategies, we focus on enhancing online presence, increasing traffic, and achieving measurable business growth.',
    },
    {
      icon: 'fas fa-robot',
      title: 'ARTIFICIAL INTELLIGENCE',
      description:
        'Our AI-driven solutions automate processes and enhance decision-making. We integrate cutting-edge AI tools to optimize performance and efficiency.',
    },
    {
      icon: 'fas fa-link',
      title: 'BLOCKCHAIN',
      description:
        'We build secure, decentralized blockchain solutions tailored to your business. Our technology ensures transparency, efficiency, and reliability.',
    },
    {
      icon: 'fas fa-server',
      title: 'SaaS APPLICATIONS',
      description:
        'Our SaaS solutions provide robust, scalable software tailored for modern enterprises. We focus on delivering seamless, cloud-based functionalities.',
    },
    {
      icon: 'fas fa-shopping-cart',
      title: 'E-COMMERCE SOLUTIONS',
      description:
        'We design user-friendly online stores focused on conversions. Our e-commerce platforms ensure a smooth shopping experience for users.',
    },
    {
      icon: 'fas fa-cogs',
      title: 'CUSTOM SOFTWARE DEVELOPMENT',
      description:
        'Offering tailor-made software solutions, we address unique business challenges and provide innovative, efficient systems to enhance productivity.',
    },
    {
      icon: 'fas fa-layer-group',
      title: 'FULL STACK DEVELOPMENT',
      description:
        'From frontend to backend, we offer end-to-end development for web and mobile apps. Our solutions are efficient, scalable, and robust.',
    },
    {
      icon: 'fas fa-calendar-alt',
      title: 'CRM DEVELOPMENT',
      description:
        'Our advanced CRM systems streamline customer management and improve interactions. We deliver user-friendly tools to boost efficiency.',
    },
    {
      icon: 'fas fa-video',
      title: 'VIDEOGRAPHY & SOCIAL MEDIA HANDLING',
      description:
        'We create engaging visual content and manage social media efficiently. Our services ensure a strong brand presence online.',
    },
    {
      icon: 'fas fa-chart-pie',
      title: 'ERP SYSTEM INTERFACE & TEAM COLLABORATION',
      description:
        'Streamline operations with custom ERP solutions. We enhance team collaboration and interface usability for better business performance.',
    },
  ];
  
    


  const handleHover = (e, isHovered) => {
    const card = e.currentTarget;
    if (isHovered) {
      card.style.backgroundColor = '#2a77c5'; // Professional blue
      card.style.color = '#ffffff'; // Text becomes white for readability
      card.style.borderColor = '#1a5aa8'; // Slightly darker blue for border
    } else {
      card.style.backgroundColor = 'black';
      card.style.color = '#f0f0f0'; // Light gray text on black
      card.style.borderColor = '#333333'; // Reset border color
    }
  };

  return (
    <div style={containerStyle}>
      {cards.map((card, index) => (
        <div
          style={cardStyle}
          key={index}
          onMouseEnter={(e) => handleHover(e, true)}
          onMouseLeave={(e) => handleHover(e, false)}
        >
          <div style={iconStyle}>
            <i className={card.icon} style={iconTextStyle}></i>
          </div>
          <h3 style={headingStyle}>{card.title}</h3>
          <p style={descriptionStyle}>{card.description}</p>
        </div>
      ))}
    </div>
  );
};

export default OurStory;
