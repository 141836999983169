import React, { useEffect, useRef } from 'react';

const InfiniteGallery = () => {
  const galleryRef = useRef(null);

  const styles = {
    container: {
      textAlign: 'center',
      padding: '50px 20px',
      color: '#121212',
    },
    h1: {
      fontSize: '32px',
      margin: '10px 0',
      fontWeight: 'bold',
    },
    h1Span: {
      color: '#ff6600', // Accent color
    },
    p: {
      fontSize: '18px',
      margin: '10px 0 40px',
      lineHeight: '1.6',
      color: '#555', // Medium gray for paragraph text
    },
    galleryWrapper: {
      overflow: 'hidden',
      width: '100%',
      position: 'relative',
      backgroundColor: '#f9f9f9',
      borderRadius: '10px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    },
    gallery: {
      display: 'flex',
      width: 'max-content',
      animation: 'scroll 30s linear infinite',
    },
    img: {
      width: '300px',
      height: '200px',
      objectFit: 'cover',
      borderRadius: '10px',
      margin: '0 10px',
      transition: 'transform 0.5s ease, filter 0.5s ease',
    },
  };

  const images = [
    'https://i.ibb.co/dKk8Ctp/IMG-7394.jpg',
    'https://i.ibb.co/xMjpHSX/IMG-1544.jpg',
    'https://i.ibb.co/pKTTMyX/IMG-7227.jpg',
    'https://i.ibb.co/sPypHMh/IMG-1547.jpg',
    'https://i.ibb.co/d2ZsF6X/IMG-7175.jpg',
    'https://i.ibb.co/J5fJG8T/IMG-7193.jpg',
    'https://i.ibb.co/kcnqj44/IMG-7984.jpg',
    'https://i.ibb.co/6bCMWSs/IMG-7981.jpg',
    'https://i.ibb.co/cc7SfTw/IMG-7300.jpg',
  ];

  // Dynamically duplicate images
  const duplicatedImages = [...images, ...images];

  // Function to pause animation
  const pauseAnimation = () => {
    if (galleryRef.current) {
      galleryRef.current.style.animationPlayState = 'paused';
    }
  };

  // Function to resume animation
  const resumeAnimation = () => {
    if (galleryRef.current) {
      galleryRef.current.style.animationPlayState = 'running';
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.h1}>
        <span style={styles.h1Span}>WORK-LIFE</span>
        <br />
        Life at Our Company
      </h1>
      <p style={styles.p}>We believe in creating more than just a workplace.</p>
      <div style={styles.galleryWrapper}>
        <div style={styles.gallery} ref={galleryRef}>
          {duplicatedImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Gallery Image ${index + 1}`}
              style={styles.img}
              onMouseEnter={(e) => {
                pauseAnimation();
                e.target.style.transform = 'scale(1.2)';
                e.target.style.filter = 'brightness(1.2)';
              }}
              onMouseLeave={(e) => {
                resumeAnimation();
                e.target.style.transform = 'scale(1)';
                e.target.style.filter = 'brightness(1)';
              }}
            />
          ))}
        </div>
      </div>
      <style>
        {`
          @keyframes scroll {
            from {
              transform: translateX(0);
            }
            to {
              transform: translateX(-50%);
            }
          }
        `}
      </style>
    </div>
  );
};

export default InfiniteGallery;
