import React from 'react';

const AwardsAndAccolades = () => {
    const awards = [
        { name: 'IOS', src: "https://img.icons8.com/?size=100&id=uoRwwh0lz3Jp&format=png&color=000000" },
        { name: 'Android', src: "https://img.icons8.com/?size=100&id=GgyRdUL5k1fr&format=png&color=000000" },
        { name: 'Flutter', src: "https://img.icons8.com/?size=100&id=7I3BjCqe9rjG&format=png&color=000000" },
        { name: 'React.js', src: "https://img.icons8.com/?size=100&id=wPohyHO_qO1a&format=png&color=000000" },
        { name: 'Shift', src: "https://img.icons8.com/?size=100&id=24465&format=png&color=000000" },
        { name: 'HTML', src: "https://img.icons8.com/?size=100&id=21278&format=png&color=000000" },
        { name: 'Java Script', src: "https://img.icons8.com/?size=100&id=PXTY4q2Sq2lG&format=png&color=000000" },
        { name: 'Angular', src: "https://img.icons8.com/?size=100&id=j9DnICNnlhGk&format=png&color=000000" },
        { name: 'Java', src: "https://img.icons8.com/?size=100&id=13679&format=png&color=000000" },
        { name: 'Node JS', src: "https://img.icons8.com/?size=100&id=hsPbhkOH4FMe&format=png&color=000000" },
        { name: 'Python', src: "https://img.icons8.com/?size=100&id=l75OEUJkPAk4&format=png&color=000000" },
        { name: 'Laravel', src: "https://img.icons8.com/?size=100&id=hUvxmdu7Rloj&format=png&color=000000" },
        { name: 'Dotnet', src: "https://img.icons8.com/?size=100&id=z2gsPTsahvBW&format=png&color=000000" },
    ];

    const containerStyle = {
        textAlign: 'center',
        padding: '50px 0',
        backgroundColor: '#000000',
        color: '#f0f0f0',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
    };

    const headingStyle = {
        color: '#ffb400',
        fontSize: '32px',
        marginBottom: '10px',
        fontFamily: 'Arial, sans-serif',
    };

    const paragraphStyle = {
        color: '#ccc',
        fontSize: '18px',
        marginBottom: '50px',
        fontFamily: 'Arial, sans-serif',
    };

    const marqueeStyle = {
        display: 'flex',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%',
        padding: '20px 0',
        backgroundColor: '#222',
        borderRadius: '5px',
    };

    const marqueeContentStyle = {
        display: 'inline-flex',
        animation: 'marquee 20s linear infinite',
    };

    const itemStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 10px',
    };

    const imageStyle = {
        width: '100px',
        height: '100px',
        borderRadius: '10px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
        transition: 'transform 0.3s ease',
    };

    const nameStyle = {
        marginTop: '10px',
        fontSize: '14px',
        color: '#ffb400',
        fontFamily: 'Arial, sans-serif',
        textAlign: 'center',
    };

    return (
        <div style={containerStyle}>
            <style>{`
                @keyframes marquee {
                    0% { transform: translateX(0); }
                    100% { transform: translateX(-50%); }
                }
            `}</style>
            <h1 style={headingStyle}>Our Technology Stack</h1>
            <p style={paragraphStyle}>
                Our diverse technology stack equips us to address any challenge and provide exceptional, customized solutions that align with our clients' needs
            </p>
            <div style={marqueeStyle}>
                <div style={marqueeContentStyle}>
                    {awards.concat(awards).map((award, index) => (
                        <div key={index} style={itemStyle}>
                            <img
                                alt={`Award ${index + 1}`}
                                src={award.src}
                                style={imageStyle}
                            />
                            <span style={nameStyle}>{award.name}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AwardsAndAccolades;
