import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faAward, faHandshake, faFileInvoiceDollar, 
  faShieldAlt, faBullseye, faFileContract, 
  faStar, faCoins, faUserFriends 
} from '@fortawesome/free-solid-svg-icons';

const EightBank = () => {
  return (
    <div style={{ backgroundColor: '#121212', minHeight: '100vh', padding: '40px 20px' }}>
      <div style={{ maxWidth: '1200px', margin: '0 auto', textAlign: 'center' }}>
        {/* Header Section */}
        <div style={{ marginBottom: '40px' }}>
          <span
            style={{
              color: '#ff6600',
              fontWeight: '700',
              fontSize: '16px',
              letterSpacing: '1px',
              textTransform: 'uppercase',
              display: 'inline-block',
              marginBottom: '10px',
            }}
          >
            WHO WE ARE
          </span>
          <h1
            style={{
              fontSize: '32px',
              fontWeight: '600',
              color: '#fff',
              marginBottom: '20px',
            }}
          >
            Built Relationships with 1100+ Happy Clients!
          </h1>
          <p
            style={{
              fontSize: '16px',
              color: '#bbb',
              maxWidth: '800px',
              margin: '0 auto',
              lineHeight: '1.6',
            }}
          >
            Companies employ software developers from us because we have a proven track record of delivering high-quality projects on time.
          </p>
        </div>

        {/* Rows Section */}
        {[
          {
            img: "https://images.pexels.com/photos/5668859/pexels-photo-5668859.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            features: [
              { icon: faAward, text: "8+ Years of Average Experience" },
              { icon: faHandshake, text: "Integrity & Transparency" },
              { icon: faFileInvoiceDollar, text: "FREE No Obligation Quote" },
            ],
            reverse: false,
          },
          {
            img: "https://images.pexels.com/photos/5668842/pexels-photo-5668842.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            features: [
              { icon: faShieldAlt, text: "Information Security" },
              { icon: faBullseye, text: "Outcome-Focused Approach" },
              { icon: faFileContract, text: "Transparency is Guaranteed" },
            ],
            reverse: true,
          },
          {
            img: "https://images.pexels.com/photos/3823488/pexels-photo-3823488.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            features: [
              { icon: faStar, text: "4.8/5 Rating on Clutch" },
              { icon: faCoins, text: "Costs Lower Than Your Local Guy" },
              { icon: faUserFriends, text: "Hire a Team of Your Choice" },
            ],
            reverse: false,
          },
        ].map((row, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: row.reverse ? 'row-reverse' : 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '40px',
              flexWrap: 'wrap',
            }}
          >
            {/* Image Section */}
            <div style={{ flex: '1 1 48%', marginBottom: '20px' }}>
              <img
                alt="Illustrative content"
                src={row.img}
                style={{
                  width: '100%',
                  borderRadius: '12px',
                  boxShadow: '0 6px 14px rgba(0, 0, 0, 0.5)',
                  transition: 'transform 0.3s ease',
                }}
              />
            </div>

            {/* Features Section */}
            <div style={{ flex: '1 1 48%', textAlign: 'left' }}>
              {row.features.map((feature, i) => (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '20px',
                    backgroundColor: '#1e1e1e',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 6px 14px rgba(0, 0, 0, 0.5)',
                  }}
                >
                  <FontAwesomeIcon
                    icon={feature.icon}
                    style={{
                      fontSize: '32px',
                      color: '#ff6600',
                      marginRight: '20px',
                    }}
                  />
                  <p
                    style={{
                      fontSize: '16px',
                      color: '#fff',
                      fontWeight: '500',
                      margin: '0',
                    }}
                  >
                    {feature.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EightBank;
