import React, { useState, useEffect } from 'react';

function PageTwoWeb() {
  const [selectedOption, setSelectedOption] = useState('');
  const [visibleBoxes, setVisibleBoxes] = useState(Array(6).fill(false));

  const handleClick = (option) => {
    setSelectedOption(option);
  };

  const getTextForOption = (option) => {
    switch (option) {
      case 'AI-Driven UI/UX Experiences':
        return 'We integrate AI to create engaging user experiences that adapt to various platforms, enhancing usability and interaction.';
      case 'Custom AI Model Development':
        return 'Our AI solutions are tailored to address your specific business needs, enabling a distinctive advantage in a data-driven world.';
      case 'AI Testing & Optimization':
        return 'We rigorously test our AI models to ensure reliability, accuracy, and security, making sure they are future-ready.';
      case 'AI Consultation & Guidance':
        return 'From conceptualization to deployment, we guide you in integrating AI that aligns with your business goals and market trends.';
      default:
        return 'Please select a service to see more details.';
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const featureBoxes = document.querySelectorAll('.feature-box');
      featureBoxes.forEach((box, index) => {
        const boxPosition = box.getBoundingClientRect().top;
        const screenPosition = window.innerHeight;

        if (boxPosition < screenPosition) {
          setVisibleBoxes((prev) => {
            const newVisibleBoxes = [...prev];
            newVisibleBoxes[index] = true;
            return newVisibleBoxes;
          });
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const styles = {
    body: {
      margin: 0,
      fontFamily: 'Roboto, sans-serif',
      background: 'linear-gradient(135deg, #121212 0%, #1f1f1f 100%)',
      color: '#fff',
      lineHeight: 1.6,
      padding: '20px',
    },
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '40px',
      borderRadius: '10px',
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.6)',
      transition: 'all 0.3s ease',
      backgroundColor: '#2a2a2a',
    },
    header: {
      fontSize: '36px',
      fontWeight: 700,
      color: '#ffb400',
      textAlign: 'center',
      marginBottom: '20px',
      letterSpacing: '2px',
      textTransform: 'uppercase',
    },
    description: {
      fontSize: '16px',
      margin: '20px auto',
      textAlign: 'center',
      lineHeight: 1.8,
      color: '#ddd',
      maxWidth: '800px',
      marginBottom: '40px',
    },
    services: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      gap: '20px',
    },
    serviceCard: {
      flex: '1 1 calc(33.333% - 20px)',
      backgroundColor: '#333',
      borderRadius: '8px',
      padding: '20px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)',
      transition: 'transform 0.3s ease',
      cursor: 'pointer',
      color: '#fff',
    },
    serviceCardHover: {
      transform: 'scale(1.05)',
      boxShadow: '0 6px 20px rgba(0, 0, 0, 0.7)',
    },
    serviceText: {
      fontSize: '18px',
      marginBottom: '10px',
      color: '#ffb400',
    },
    serviceDetails: {
      fontSize: '14px',
      color: '#ccc',
    },
    icon: {
      textAlign: 'center',
      marginTop: '20px',
    },
    iconSize: {
      fontSize: '50px',
      color: '#ffb400',
      animation: 'pulse 2s infinite',
    },
    featureBox: {
      backgroundColor: '#2e2e2e',
      padding: '30px',
      borderRadius: '12px',
      textAlign: 'left',
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.4)',
      transition: 'all 0.6s ease',
      position: 'relative',
    },
    featureBoxVisible: {
      opacity: 1,
      transform: 'translateY(0)',
    },
    featureBoxHidden: {
      opacity: 0,
      transform: 'translateY(30px)',
    },
    heading: {
      color: 'orange',
      fontSize: '16px',
      textTransform: 'uppercase',
      marginBottom: '10px',
    },
    mainHeading: {
      fontSize: '36px',
      marginBottom: '20px',
    },
    featureGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      gap: '20px',
      maxWidth: '1200px',
      margin: '0 auto',
    },
   whyChooseUsSection: {
    backgroundColor: '#000000', // Slightly lighter dark background for card effect
    padding: '50px', // Add uniform padding to make it look like a card
    textAlign: 'center',
    overflowX: 'hidden',
    width: '80vw', // Adjust the width to make it more card-like
    margin: '0 auto', // Center it horizontally
    position: 'relative', // Ensures it sits correctly in relation to other elements
    borderRadius: '16px', // Rounded corners to create card effect
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.8)', // Adds shadow to make it pop
    marginTop: '50px', // Adds space above the big card

  },
  
    whyChooseUsSection: {
      backgroundColor: '#121212',
      color: '#ffffff',
      padding: '40px 20px',
      textAlign: 'center',
    },
    featureGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      
      gap: '20px',
      marginTop: '20px',
    },
    featureBox: {
      backgroundColor: '#1a1a1a',
      borderRadius: '8px',
      padding: '20px',
      textAlign: 'center',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    },
    featureBoxVisible: {
      transform: 'translateY(0)',
      opacity: 1,
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    featureBoxHidden: {
      transform: 'translateY(20px)',
      opacity: 0,
    },
    featureBoxHover: {
      transform: 'scale(1.05)',
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
    },
  

  };

  const features = [
    {
      icon: 'https://img.icons8.com/?size=100&id=yBmsTPyQIvAi&format=png&color=000000',
      title: 'Competitive Rates',
      description: 'Our rates are highly competitive, ensuring that you receive excellent value for your money. With us, you can be confident that you are getting the best possible rates without compromising on quality.',
    },
    {
      icon: 'https://img.icons8.com/?size=100&id=gutwVEcIEFpc&format=png&color=000000',
      title: 'Quality',
      description: 'We take pride in delivering exceptional results. Our CMMI level 3 appraisal and membership in the Agile Alliance demonstrate our commitment to strong processes and quality control.',
    },
   
    {
      icon: 'https://img.icons8.com/?size=100&id=Ryvrr3jc5orN&format=png&color=000000',
      title: 'Security & Confidentiality',
      description: 'Unlike many offshore companies, security is our top priority. Your data and intellectual property remain completely confidential, and all source code rights belong to you.',
    },
    {
      icon: 'https://img.icons8.com/?size=100&id=xaZLD9N5l16s&format=png&color=000000',
      title: 'On-Time Delivery',
      description: 'We use cutting-edge project management tools and agile development practices to keep your project on track.',
    },
    {
      icon: 'https://img.icons8.com/?size=100&id=8jgJ6GgimhsJ&format=png&color=000000',
      title: 'Flexible Engagement Models',
      description: 'We offer flexible engagement options that adapt as your needs evolve. Choose the model that works best for you now.',
    },
    {
      icon: 'https://img.icons8.com/?size=100&id=NVIZnI2CZTno&format=png&color=000000',
      title: 'Experienced Team',
      description: 'Just like the skilled professionals at Kotoibox Global Technologies, our team has the expertise to handle complex projects with precision and creativity.',
    },
    {
      icon: 'https://img.icons8.com/?size=100&id=5mc3wKND3Ps8&format=png&color=000000',
      title: 'Client-Centric Approach',
      description: 'Much like the personal stories your grandma shared with you, we approach every project with care, ensuring your unique vision is fully realized.',
    },
    {
      icon: 'https://img.icons8.com/?size=100&id=6KZpxj5Wg8u6&format=png&color=000000',
      title: '24/7 Support',
      description: 'Our support team is available at any time, much like the dependable service you seek when tackling your restaurant’s website design. We’re always here to help.',
    }
    
  ];

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div style={styles.header}>Innovative AI Solutions for Your Business</div>
        <div style={styles.description}>
        As a leader in AI development, we bring the power of artificial intelligence to transform your business operations. Our solutions are crafted to deliver smarter, automated processes.
        </div>
        <div style={styles.services}>
          {['AI-Driven UI/UX Experiences', 'Custom AI Model Development', 'AI Testing & Optimization', 'AI Consultation & Guidance'].map((option) => (
            <div
              key={option}
              style={{
                ...styles.serviceCard,
                ...(selectedOption === option ? styles.serviceCardHover : {}),
              }}
              onClick={() => handleClick(option)}
            >
              <div style={styles.serviceText}>{option}</div>
              <div style={styles.serviceDetails}>{getTextForOption(option)}</div>
            </div>
          ))}
        </div>
        <div style={styles.icon}>
          <i className="fas fa-lightbulb" style={styles.iconSize}></i>
        </div>
      </div>

      {/* WHY CHOOSE US Section */}
      <div style={styles.whyChooseUsSection}>
      <div className="heading">
        <h5 style={{ color: 'orange', fontSize: '16px', textTransform: 'uppercase', marginBottom: '10px' }}>WHY CHOOSE US</h5>
        <h1 style={{ fontSize: 'clamp(24px, 5vw, 36px)', marginBottom: '20px' }}>Advantages of Working with KotiBox for AI</h1>
        <p style={{
          fontSize: '16px',
          color: '#b3b3b3',
          maxWidth: '800px',
          margin: '0 auto 40px',
        }}>
          When it comes to crafting custom AI, you deserve a partner who prioritizes both your vision and your users' safety. Here's what sets us apart:
        </p>
      </div>
      <div className="feature-grid" style={styles.featureGrid}>
        {features.map((feature, index) => (
          <div
            key={index}
            className="feature-box"
            style={styles.featureBox}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = styles.featureBoxHover.transform;
              e.currentTarget.style.boxShadow = styles.featureBoxHover.boxShadow;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = styles.featureBox.transform;
              e.currentTarget.style.boxShadow = styles.featureBox.boxShadow;
            }}
          >
            <img src={feature.icon} alt={feature.title} style={{ width: '50px', height: '50px', marginBottom: '10px' }} />
            <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>{feature.title}</h3>
            <p style={{ fontSize: '14px', color: '#cccccc' }}>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
    </div>
    
  );
}

export default PageTwoWeb;
