import React, { useState } from 'react';

const cardData = [
  { title: 'Website Development', content: 'Developers at Kotibox specialize in building responsive, high-performance websites that deliver seamless user experiences across all devices.....' },
  { title: 'Application Development', content: 'Mobile app developers at Kotibox create intuitive, scalable apps that provide exceptional performance and align with your business goals....' },
  { title: 'Digital Marketing', content: 'Digital marketing experts at Kotibox design data-driven strategies that boost visibility, engagement, and conversions to accelerate business growth....' },
  { title: 'Blockchain Solutions', content: 'Blockchain developers at Kotibox create secure, transparent decentralized solutions that drive innovation and unlock new business opportunities....' },
  { title: 'E-commerce Solutions', content: 'E-commerce developers at Kotibox build secure, scalable online stores that enhance customer experiences and drive sales growth.s...' },
  { title: 'Custom Software & Full Stack Development', content: 'Full-stack developers at Kotibox deliver custom software solutions that integrate seamlessly across front-end and back-end, ensuring high performance and scalability....' },
  { title: ' CRM & ERP Platform', content: 'CRM & ERP developers at Kotibox design and implement tailored platforms that streamline business processes and enhance operational efficiency....' }
];

const CardCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(1); // Start with the second card in focus

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? cardData.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === cardData.length - 1 ? 0 : prevIndex + 1));
  };

  const getCardStyle = (index) => {
    const isCenterCard = index === currentIndex;
    const isLeftCard = index === (currentIndex - 1 + cardData.length) % cardData.length;
    const isRightCard = index === (currentIndex + 1) % cardData.length;

    if (isCenterCard) {
      return {
        transform: 'scale(1.1)',
        background: '#E0B030', // Yellow background for center card
        color: '#181818',
        padding: '20px',
        borderRadius: '15px',
        boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.3)',
        transition: 'transform 0.3s, background 0.3s, box-shadow 0.3s',
        width: '350px',
        margin: '0 15px',
        zIndex: 2,
      };
    } else if (isLeftCard || isRightCard) {
      return {
        transform: 'scale(0.9)',
        background: '#333', // Dark background for side cards
        color: '#fff',
        padding: '20px',
        borderRadius: '15px',
        opacity: 0.7,
        transition: 'transform 0.3s, opacity 0.3s',
        width: '300px',
        margin: '0 15px',
        zIndex: 1,
      };
    } else {
      return { display: 'none' }; // Hide the cards that are out of view
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: '#181818',
        padding: '50px 0',
        overflow: 'hidden',
      }}
    >
      <h2 style={{ color: '#fff', fontSize: '24px', textAlign: 'center', marginBottom: '20px' }}>
        Hire Kotibox JavaScript Developers for Building Complex Web Solutions
      </h2>
      <p
        style={{
          color: '#999',
          fontSize: '16px',
          textAlign: 'center',
          maxWidth: '800px',
          marginBottom: '30px',
        }}
      >
        Our team of JavaScript developers are known to build robust web applications that run on multiple platforms...
      </p>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          width: '100%',
          marginBottom: '30px',
        }}
      >
        <button
          onClick={handlePrev}
          style={{
            color: '#f5b800',
            fontSize: '32px',
            cursor: 'pointer',
            background: 'none',
            border: 'none',
            transition: 'transform 0.3s',
          }}
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.1)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        >
          ◀
        </button>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            maxWidth: '900px',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {cardData.map((card, index) => (
            <div key={index} style={getCardStyle(index)}>
              <h3
                style={{
                  color: index === currentIndex ? '#181818' : '#f5b800',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                  textAlign: 'center',
                }}
              >
                {card.title}
              </h3>
              <p style={{ textAlign: 'center', fontSize: '14px' }}>{card.content}</p>
            </div>
          ))}
        </div>
        <button
          onClick={handleNext}
          style={{
            color: '#f5b800',
            fontSize: '32px',
            cursor: 'pointer',
            background: 'none',
            border: 'none',
            transition: 'transform 0.3s',
          }}
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.1)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        >
          ▶
        </button>
      </div>
     
    </div>
  );
};

export default CardCarousel;
