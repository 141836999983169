import React from 'react';

const PageFiveDigital = () => {
  const styles = {
    body: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: '#121212', // Dark mode background
      color: '#ffffff',
      padding: 0,
      margin: 0,
    },
    container: {
      padding: '50px 15px',
    },
    headerSpan: {
      color: '#f97316',
      fontSize: '14px',
      fontWeight: 700,
      textAlign: 'center',
      display: 'block',
      marginBottom: '10px',
    },
    headerH1: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: 1.3,
      textAlign: 'center',
      marginBottom: '30px',
    },
    serviceCard: {
      backgroundColor: '#1e1e1e', // Dark card background
      borderRadius: '12px',
      padding: '20px',
      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      margin: '0 10%', // 10% margin on left and right
      maxWidth: '80%', // Restrict card size
    },
    serviceCardHover: {
      transform: 'translateY(-10px)',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
    },
    icon: {
      fontSize: '40px',
      color: '#f97316',
    },
    serviceCardH3: {
      fontSize: '20px',
      fontWeight: 700,
      margin: '20px 0 10px',
      textAlign: 'center',
      color: '#ffffff',
    },
    serviceCardP: {
      fontSize: '14px',
      lineHeight: 1.6,
      color: '#b0b0b0',
      textAlign: 'justify',
    },
    highlightIcon: {
      fontSize: '14px',
      color: '#f97316',
      marginRight: '10px',
    },
    highlightSpan: {
      backgroundColor: '#f97316',
      color: '#ffffff',
      padding: '5px 10px',
      borderRadius: '20px',
      fontSize: '12px',
    },
    arrowIcon: {
      fontSize: '20px',
      color: '#f97316',
    },
  };

  const serviceData = [
    {
        icon: 'fas fa-bullhorn',
        title: 'Digital Marketing Strategy',
        description:
          'Create a comprehensive digital marketing strategy that aligns with your business goals. We develop data-driven plans that target the right audience, increase brand awareness, and drive measurable results across digital platforms.',
        highlights: [
          'Tailored Digital Marketing Plans',
          'Audience Targeting and Segmentation',
          'Brand Awareness and Visibility',
          'Data-Driven Marketing Decisions',
        ],
      },
      {
        icon: 'fas fa-search',
        title: 'Search Engine Optimization (SEO)',
        description:
          'Improve your website’s visibility on search engines and drive organic traffic with our SEO services. We optimize your content, keywords, and technical elements to help you rank higher on Google and other search engines.',
        highlights: [
          'On-Page and Off-Page SEO',
          'Keyword Research and Optimization',
          'Improved Search Engine Rankings',
          'Organic Traffic Growth',
        ],
      },
      {
        icon: 'fas fa-ad',
        title: 'Pay-Per-Click (PPC) Advertising',
        description:
          'Maximize your ROI with PPC campaigns that target your ideal customers. We create effective Google Ads and social media ads that drive qualified traffic to your site and convert visitors into customers.',
        highlights: [
          'Google Ads and Social Media Ads',
          'Targeted PPC Campaigns',
          'High-Conversion Landing Pages',
          'Measurable Results and ROI',
        ],
      },
      {
        icon: 'fas fa-share-alt',
        title: 'Social Media Marketing',
        description:
          'Engage your audience and boost brand presence on social media platforms. We create shareable, engaging content and run targeted campaigns on platforms like Facebook, Instagram, LinkedIn, and Twitter to increase follower growth and interaction.',
        highlights: [
          'Social Media Content Creation',
          'Targeted Ad Campaigns',
          'Community Engagement',
          'Brand Loyalty and Advocacy',
        ],
      },
      {
        icon: 'fas fa-video',
        title: 'Video Marketing',
        description:
          'Harness the power of video to communicate your message and connect with your audience. We create compelling video content for YouTube, social media, and websites that engage viewers and increase brand awareness.',
        highlights: [
          'Engaging Video Content Creation',
          'Platform-Specific Video Strategy',
          'Increased Audience Engagement',
          'Brand Storytelling Through Video',
        ],
      },
      {
        icon: 'fas fa-envelope',
        title: 'Email Marketing Campaigns',
        description:
          'Drive conversions with personalized email marketing campaigns. We design and send targeted email campaigns that nurture leads, engage customers, and promote special offers and new products to increase sales.',
        highlights: [
          'Personalized Email Content',
          'Targeted Email Campaigns',
          'Lead Nurturing and Conversion',
          'High Open and Click-Through Rates',
        ],
      },
      {
        icon: 'fas fa-chart-line',
        title: 'Analytics and Performance Tracking',
        description:
          'Track and measure the effectiveness of your digital marketing efforts with comprehensive analytics. We use tools like Google Analytics and social media insights to monitor performance, optimize campaigns, and improve ROI.',
        highlights: [
          'Comprehensive Analytics Tools',
          'Performance Metrics and KPIs',
          'Campaign Optimization',
          'Improved Marketing ROI',
        ],
      },
      {
        icon: 'fas fa-mobile-alt',
        title: 'Mobile Marketing',
        description:
          'Reach your customers on-the-go with mobile marketing strategies. We create mobile-optimized websites, apps, and SMS campaigns that ensure your brand is visible and accessible on smartphones and tablets.',
        highlights: [
          'Mobile-Friendly Website Design',
          'SMS and Push Notifications',
          'App Marketing and User Engagement',
          'Location-Based Targeting',
        ],
      },
      {
        icon: 'fas fa-podcast',
        title: 'Content Marketing and Blogging',
        description:
          'Drive traffic and build your brand’s authority with high-quality content. We create informative blog posts, articles, and resources that provide value to your audience and help position your brand as an industry leader.',
        highlights: [
          'SEO-Optimized Content Creation',
          'Engaging and Informative Blogs',
          'Content Strategy Development',
          'Audience Growth and Retention',
        ],
      }
      
  ];

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div>
          <span style={styles.headerSpan}>SERVICES</span>
          <h1 style={styles.headerH1}>
            Our Expertise In Digital Marketing
          </h1>
        </div>
        <div className="row g-4">
          {serviceData.map((service, index) => (
            <div className="col-12 col-md-6 col-lg-4" key={index}>
              <div
                style={styles.serviceCard}
                onMouseOver={(e) =>
                  Object.assign(e.currentTarget.style, styles.serviceCardHover)
                }
                onMouseOut={(e) =>
                  Object.assign(e.currentTarget.style, {
                    transform: 'translateY(0)',
                    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                  })
                }
              >
                <div className="text-center mb-3" style={styles.icon}>
                  <i className={service.icon}></i>
                </div>
                <h3 style={styles.serviceCardH3}>{service.title}</h3>
                <p style={styles.serviceCardP}>{service.description}</p>
                <div>
                  {service.highlights.map((highlight, idx) => (
                    <div key={idx} className="d-flex align-items-center mb-2">
                      <i
                        className="fas fa-check"
                        style={styles.highlightIcon}
                      ></i>
                      <span style={styles.highlightSpan}>{highlight}</span>
                    </div>
                  ))}
                </div>
                <div className="text-end">
                  <i className="fas fa-arrow-right" style={styles.arrowIcon}></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PageFiveDigital;
