import React from "react";

const Devthree = () => {
  const styles = {
    container: {
      backgroundColor: "#000", // Black background
      color: "#fff",
      padding: "50px",
      textAlign: "center",
      fontFamily: "Arial, sans-serif",
    },
    heading: {
      fontSize: "36px",
      color: "#FFD700", // Yellow color
      marginBottom: "20px",
      textShadow: "0 2px 10px rgba(255, 215, 0, 0.8)",
    },
    cardsContainer: {
      display: "flex",
      justifyContent: "center",
      gap: "20px",
      marginTop: "30px",
      flexWrap: "wrap",
    },
    card: {
      border: "2px solid #FFD700", // Yellow border
      borderRadius: "10px",
      padding: "20px",
      width: "300px",
      backgroundColor: "#111",
      color: "#fff",
      textAlign: "left",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.4)",
      transition: "transform 0.3s, box-shadow 0.3s",
    },
    cardHover: {
      transform: "translateY(-10px)",
      boxShadow: "0 8px 12px rgba(255, 215, 0, 0.4)",
    },
    cardHeading: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#FFD700", // Yellow color
      marginBottom: "10px",
    },
    cardDescription: {
      fontSize: "14px",
      lineHeight: "1.6",
    },
  };

  // Function to add hover effect
  const applyHoverEffect = (e) => {
    e.currentTarget.style.transform = styles.cardHover.transform;
    e.currentTarget.style.boxShadow = styles.cardHover.boxShadow;
  };

  // Function to remove hover effect
  const removeHoverEffect = (e) => {
    e.currentTarget.style.transform = "none";
    e.currentTarget.style.boxShadow = styles.card.boxShadow;
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Our Engagement Models</h1>
      <div style={styles.cardsContainer}>
        {/* Card 1 */}
        <div
          style={styles.card}
          onMouseEnter={applyHoverEffect}
          onMouseLeave={removeHoverEffect}
        >
          <h2 style={styles.cardHeading}>Fix Cost and Time</h2>
          <p style={styles.cardDescription}>
            We estimate the scope of the project after gathering a complete
            understanding of the clear requirements and the hours it would take
            for development and deployment. Once we have gathered the data, we
            share a fixed cost and time estimate with you of when we will
            deliver the project.
          </p>
        </div>

        {/* Card 2 */}
        <div
          style={styles.card}
          onMouseEnter={applyHoverEffect}
          onMouseLeave={removeHoverEffect}
        >
          <h2 style={styles.cardHeading}>On Site</h2>
          <p style={styles.cardDescription}>
            We send our resources to your place of operation to work alongside
            your team on the project. This model suits businesses that have a
            skilled team but require an expert to take care of certain aspects
            of the project. It’s cost-effective, risk-free, fast, and 100%
            transparent.
          </p>
        </div>

        {/* Card 3 */}
        <div
          style={styles.card}
          onMouseEnter={applyHoverEffect}
          onMouseLeave={removeHoverEffect}
        >
          <h2 style={styles.cardHeading}>Time and Material</h2>
          <p style={styles.cardDescription}>
            We use the Time & Material Model when the software is in the range
            of medium to high complexity. Here, we charge you for the number and
            time of resources that we have employed to develop your software, on
            an hourly level. This model best suits businesses that require
            constant updates.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Devthree;
