import React, { useState } from 'react';

const FiveBankcontent = {
  'Healthcare': {
    title: 'Healthcare',
    description: 'We provide top-notch healthcare app development services to ensure the best user experience and functionality.',
  },
  'E-commerce': {
    title: 'E-commerce',
    description: 'Our e-commerce solutions are designed to enhance your online business and provide a seamless shopping experience.',
  },
  'Social Media': {
    title: 'Social Media',
    description: 'We create engaging social media platforms that connect people and foster communication.',
  },
  'Business': {
    title: 'Business',
    description: 'Our business applications are tailored to meet the unique needs of your organization and drive growth.',
  },
  'Sports': {
    title: 'Sports',
    description: 'We develop sports applications that provide real-time updates, scores, and analytics for sports enthusiasts.',
  },
  'Fintech': {
    title: 'Fintech',
    description: 'Our fintech solutions are designed to streamline financial services and enhance user experience.',
  },
  'Food Delivery': {
    title: 'Food Delivery',
    description: 'We build food delivery apps that offer a seamless ordering experience and efficient delivery management.',
  },
  'On-Demand': {
    title: 'On-Demand',
    description: 'Our on-demand app development services cater to various industries, providing quick and reliable solutions.',
  },
  'Health & Fitness': {
    title: 'Health & Fitness',
    description: 'We create health and fitness apps that help users track their progress and achieve their fitness goals.',
  },
  'Education & M-learning': {
    title: 'Education & M-learning',
    description: 'Our education and m-learning solutions are designed to provide an interactive and engaging learning experience.',
  },
  'Games': {
    title: 'Games',
    description: 'We develop immersive and engaging games that provide entertainment and challenge for players.',
  },
  'Travel & Transport': {
    title: 'Travel & Transport',
    description: 'We strike an amazing balance between human-centric design, UI, and a powerful technology stack to create flexible, feature-rich travel & transport app development.',
  }
};

function FiveBank() {
  const [content, setContent] = useState(FiveBankcontent['Healthcare']);

  const showContent = (key) => {
    setContent(FiveBankcontent[key]);
  };

  const styles = {
    body: {
      backgroundColor: '#1a1a1a',
      color: '#ffffff',
      fontFamily: 'Arial, sans-serif',
      margin: 0,
      padding: 0,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '50px 20px',
    },
    header: {
      textAlign: 'center',
      marginBottom: '50px',
    },
    headerTitle: {
      fontSize: '2.5em',
      margin: 0,
      fontWeight: '600',
      color: '#ffffff',
    },
    headerSpan: {
      borderBottom: '5px solid #f0a500',
    },
    headerText: {
      color: '#f0a500',
      fontSize: '1.2em',
      marginTop: '10px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: '1200px',
    },
    columns: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Center all elements vertically
      alignItems: 'center',
      width: '100%',
    },
    buttonRow: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '20px',
      width: '100%',
    },
    leftColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: '200px',
      margin: '10px 0',
    },
    columnText: {
      fontSize: '1.1em',
      margin: '10px 0',
      cursor: 'pointer',
      transition: 'color 0.3s ease',
      fontWeight: '600',
      border: '2px solid transparent',
      borderRadius: '25px',
      padding: '10px 20px',
      backgroundColor: '#333',
      textAlign: 'center',
      color: '#fff',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.3s ease',
      minWidth: '150px',
      textTransform: 'uppercase',
    },
    columnTextHover: {
      color: '#fff',
      backgroundColor: '#f0a500',
      borderColor: '#f0a500',
      boxShadow: '0 4px 12px rgba(240, 165, 0, 0.3)',
      transform: 'scale(1.05)',
    },
    centerColumn: {
      width: '100%',
      maxWidth: '600px',
      textAlign: 'center',
      margin: '20px 0',
      padding: '20px',
      backgroundColor: '#2a2a2a',
      borderRadius: '10px',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
      transition: 'transform 0.3s ease',
    },
    centerColumnHover: {
      transform: 'scale(1.05)',
    },
    centerTitle: {
      color: '#f0a500',
      fontSize: '2.5em',
      margin: '0',
      fontWeight: '700',
      textTransform: 'uppercase',
    },
    centerText: {
      fontSize: '1.2em',
      margin: '20px 0',
      lineHeight: '1.6',
      color: '#ccc',
    },
    button: {
      display: 'inline-block',
      padding: '12px 25px',
      marginTop: '20px',
      border: '2px solid #f0a500',
      borderRadius: '25px',
      color: '#f0a500',
      textDecoration: 'none',
      fontSize: '1.2em',
      transition: 'background-color 0.3s, color 0.3s',
    },
    buttonHover: {
      backgroundColor: '#f0a500',
      color: '#1a1a1a',
    },
    highlight: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '20px',
    },
    highlightSpan: {
      borderBottom: '2px solid #f0a500',
      paddingBottom: '5px',
      marginRight: '10px',
    },
    '@media (max-width: 768px)': {
      columns: {
        flexDirection: 'column',
        alignItems: 'center',
      },
      leftColumn: {
        alignItems: 'center',
      },
      columnText: {
        width: '100%',
        maxWidth: '300px',
        margin: '10px auto',
      },
      centerColumn: {
        maxWidth: '90%',
      },
      centerTitle: {
        fontSize: '2em',
      },
      centerText: {
        fontSize: '1.1em',
      },
    },
  };

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div style={styles.header}>
          <h1 style={styles.headerTitle}><span style={styles.headerSpan}>Other Domain</span></h1>
          <p style={styles.headerText}>We are one of the most trusted software development agencies when it comes to building applications for various industries.</p>
        </div>
        <div style={styles.content}>
          <div style={styles.buttonRow}>
            {/* Display first 7 buttons */}
            {Object.keys(FiveBankcontent).slice(0, 7).map((key) => (
              <button
                key={key}
                style={styles.columnText}
                onClick={() => showContent(key)}
              >
                {FiveBankcontent[key].title}
              </button>
            ))}
          </div>

          {/* Description Section */}
          <div style={{ ...styles.centerColumn, ...styles.centerColumnHover }}>
            <h2 style={styles.centerTitle}>{content.title}</h2>
            <p style={styles.centerText}>{content.description}</p>
          </div>

          <div style={styles.buttonRow}>
            {/* Display next 7 buttons */}
            {Object.keys(FiveBankcontent).slice(7).map((key) => (
              <button
                key={key}
                style={styles.columnText}
                onClick={() => showContent(key)}
              >
                {FiveBankcontent[key].title}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FiveBank;
