import React, { useState } from 'react';

function FourMedia() {
  const [activeTab, setActiveTab] = useState('frontend');

  const showContent = (tab) => {
    setActiveTab(tab);
  };

  const containerStyle = {
    backgroundColor: '#000000', // Background color
    color: '#ffffff',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    padding: '50px 0',
    margin: 0,
  };

  const titleStyle = {
    fontSize: '36px',
    letterSpacing: '5px',
    marginBottom: '50px',
  };

  const tabStyle = (isActive) => ({
    padding: '10px 20px',
    cursor: 'pointer',
    margin: '0 10px',
    color: isActive ? '#ffcc00' : '#ffffff',
    fontWeight: isActive ? 'bold' : 'normal',
    borderBottom: isActive ? '2px solid #ffcc00' : 'none',
    transition: 'color 0.3s ease',
  });

  const logoBoxStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  };

  const itemStyle = {
    width: '150px',
    margin: '20px',
    textAlign: 'center',
    transition: 'transform 0.3s ease',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '10px',
    backgroundColor: '#2a2a2a',
    padding: '10px',
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.style.transform = 'scale(1.05)';
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.transform = 'scale(1)';
  };

  // Example logo data
  const logoData = {
    frontend: [
      'https://img.icons8.com/?size=100&id=Nlsua06Gvxel&format=png', 
      'https://img.icons8.com/?size=100&id=b5Z5U3tVLQu5&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=20909&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=21278&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=108784&format=png&color=000000',
    ],
    framework: [
      'https://img.icons8.com/?size=100&id=2ZOaTclOqD4q&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=hsPbhkOH4FMe&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=bzf0DqjXFHIW&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=71257&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=tbleCw0ch6QC&format=png&color=000000',
    ],
    platform: [
      'https://img.icons8.com/?size=100&id=17842&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=gXoJoyTtYXFg&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=uoRwwh0lz3Jp&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=VoXRGxL3ekkk&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=cdYUlRaag9G9&format=png&color=000000',
    ],
  };

  return (
    <div style={containerStyle}>
      <div style={titleStyle}>TECHNOLOGIES</div>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
        <div style={tabStyle(activeTab === 'frontend')} onClick={() => showContent('frontend')}>
          Frontend
        </div>
        <div style={tabStyle(activeTab === 'framework')} onClick={() => showContent('framework')}>
          Framework
        </div>
        <div style={tabStyle(activeTab === 'platform')} onClick={() => showContent('platform')}>
          Platform
        </div>
      </div>
      <div style={logoBoxStyle}>
        {logoData[activeTab].map((logoUrl, index) => (
          <div
            key={index}
            style={itemStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={logoUrl}
              alt={`Logo ${index + 1}`}
              style={{ width: '80px', height: '80px', marginBottom: '10px' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default FourMedia;
