import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faProjectDiagram, faUsers, faGlobe, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const Advantages = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const containerStyle = {
        textAlign: 'center',
        padding: '50px 20px',
        backgroundColor: '#1C1C1C',
        color: '#FFFFFF',
    };

    const headerStyle = {
        color: '#ff7a00',
        fontWeight: 700,
        marginBottom: '10px',
    };

    const titleStyle = {
        fontSize: '36px',
        fontWeight: 700,
        marginBottom: '40px',
    };

    const cardsStyle = {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        flexWrap: 'wrap',
    };

    const cardStyle = {
        backgroundColor: '#000000',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        width: '250px',
        textAlign: 'left',
        transition: 'transform 0.3s, box-shadow 0.3s',
    };

    const cardHoverStyle = {
        transform: 'scale(1.05)', // Zoom effect
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    };

    const iconStyle = {
        fontSize: '40px',
        color: '#ff7a00',
        marginBottom: '20px',
    };

    const h3Style = {
        fontSize: '18px',
        fontWeight: 700,
        marginBottom: '20px',
    };

    const ulStyle = {
        listStyle: 'none',
        padding: 0,
    };

    const liStyle = {
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
    };

    const buttonContainerStyle = {
        marginTop: '40px',
    };

    const buttonStyle = {
        backgroundColor: '#ff7a00',
        color: '#fff',
        border: 'none',
        padding: '15px 30px',
        fontSize: '16px',
        fontWeight: 700,
        borderRadius: '5px',
        cursor: 'pointer',
    };

    return (
        <div style={containerStyle}>
            <div style={headerStyle}>UNMATCHED EXPERTISE</div>
            <div style={titleStyle}>Why Choose Kotibox for Your Development Needs?</div>
            <div style={cardsStyle}>
                {[ 
                    {
                        icon: faCode,
                        title: 'Affordable & High-Impact Solutions',
                        items: [
                            'No Hidden Fees, Just Transparent Pricing',
                            'Free QA & Project Management Services',
                            'Unbeatable Cost Efficiency for Maximum ROI',
                        ],
                    },
                    {
                        icon: faProjectDiagram,
                        title: 'Innovative, Transparent Approach',
                        items: [
                            'Clear, Direct, and Local Communication ',
                            'Real-Time Collaboration with Full Accountability',
                            'Agile, Adaptive Development for Fast Results',
                        ],
                    },
                    {
                        icon: faUsers,
                        title: 'Comprehensive, End-to-End Solutions',
                        items: [
                            '25+ Expert In-House Developers Ready to Scale',
                            'Full-Stack Development Across All Platforms  ',
                            'Industry-Specific Teams Tailored to Your Needs',
                        ],
                    },
                    {
                        icon: faGlobe,
                        title: 'Reliable, Trusted Partners for Success',
                        items: [
                            'Seamless Onboarding in Just 48 Hours',
                            'Building Long-Term, Impactful Relationships ',
                            'Over 9 Years of Proven Expertise and Success',
                        ],
                    },
                ].map((card, index) => (
                    <div
                        key={index}
                        style={{
                            ...cardStyle,
                            ...(hoveredIndex === index ? cardHoverStyle : {}),
                        }}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                    >
                        <FontAwesomeIcon icon={card.icon} style={iconStyle} />
                        <h3 style={h3Style}>{card.title}</h3>
                        <ul style={ulStyle}>
                            {card.items.map((item, idx) => (
                                <li key={idx} style={liStyle}>
                                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#ff7a00', marginRight: '10px' }} />
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
           
        </div>
    );
};

export default Advantages;
