import React, { useState } from 'react';
import servideo from '../../imgvdo/servideo.gif';

// FAQ Component
const PageFourCRM = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const styles = {
    body: {
      backgroundColor: '#1B1B1B',
      color: '#ffffff',
      fontFamily: '"Roboto", sans-serif',
      margin: '0',
      padding: '0',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    faqContainer: {
      maxWidth: '800px',
      width: '90%',
      margin: '20px',
      padding: '30px',
      backgroundColor: '#1f1f1f',
      borderRadius: '10px',
      boxShadow: '0 10px 30px rgba(0, 0, 0, 0.5)',
    },
    faqHeader: {
      textAlign: 'center',
      fontSize: '2rem',
      marginBottom: '20px',
      letterSpacing: '1px',
      color: '#ffcc00',
    },
    faqItem: {
      borderBottom: '1px solid #444',
      padding: '20px 0',
      transition: 'background-color 0.3s ease',
    },
    faqQuestion: {
      fontSize: '1.1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      color: '#ffffff',
      padding: '15px',
      borderRadius: '5px',
      backgroundColor: '#2e2e2e',
    },
    faqAnswer: {
      maxHeight: '0',
      overflow: 'hidden',
      transition: 'max-height 0.5s ease, opacity 0.5s ease',
      opacity: 0,
      paddingTop: '0',
    },
    faqAnswerShow: {
      paddingTop: '10px',
      fontSize: '1rem',
      lineHeight: '1.6',
      color: '#d3d3d3',
    },
    icon: {
      fontSize: '1.5rem',
      transition: 'transform 0.3s ease',
    },
    processContainer: {
      backgroundColor: '#000000',
      color: 'white',
      minHeight: '100vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
    },
    processContent: {
      width: '90%',
      maxWidth: '1000px',
      margin: '0 auto',
      textAlign: 'left',
    },
    processTitle: {
      fontSize: '2rem',
      marginBottom: '1rem',
      fontWeight: 'bold',
    },
    processSubtitle: {
      fontSize: '1rem',
      marginBottom: '2rem',
      color: '#bbb',
    },
    processImage: {
      maxWidth: '100%',
      height: 'auto',
      borderRadius: '8px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)',
    },
  };

  return (
    <div style={styles.body}>
      <div style={styles.faqContainer}>
        <div style={styles.faqHeader}>Frequently Asked Questions</div>
        {faqData.map((faq, index) => (
          <div key={index} style={styles.faqItem}>
            <div
              style={styles.faqQuestion}
              onClick={() => toggleFaq(index)}
            >
              {faq.question}
              <i
                className={`fas ${openIndex === index ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                style={{
                  ...styles.icon,
                  color: '#ffcc00',
                  transform: openIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </div>
            <div
              style={{
                ...styles.faqAnswer,
                ...(openIndex === index ? { maxHeight: '500px', opacity: 1, paddingTop: '10px' } : {}),
              }}
            >
              <p style={styles.faqAnswerShow}>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>

      <div style={styles.processContainer}>
        <div style={styles.processContent}>
          <h1 style={styles.processTitle}>Our Process</h1>
          <h2 style={styles.processSubtitle}>
            Unlock your true potential, leverage the best of technology, and create applications based on a modern algorithm with our experts.
          </h2>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={servideo}
              alt="Demo GIF"
              style={styles.processImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// Example FAQ data array for the questions and answers
const faqData = [
  {
    question: 'What does a website development company do?',
    answer: 'A website development company specializes in creating, designing, and maintaining websites. They handle everything from front-end design to back-end development, ensuring a functional, user-friendly, and responsive website that meets your business goals.',
  },
  {
    question: 'How much does it cost to develop a website?',
    answer: 'The cost of website development varies depending on the complexity, features, and design requirements. At KotiBox, we offer tailored solutions to meet your budget and objectives. Contact us for a customized quote.',
  },
  {
    question: 'Which tools and technologies do you use for website development?',
    answer: 'We use the latest technologies like HTML5, CSS3, JavaScript, React, and modern CMS platforms to build secure, scalable websites with a smooth user experience.',
  },
  {
    question: 'Does KotiBox offer a free consultation or quote for website development?',
    answer: 'Yes, we provide a free initial consultation to discuss your website goals and provide a quote based on your specific needs.',
  },
];

// Other Services Component
const OtherServices = () => {
  const containerStyle = {
    padding: '20px',
    backgroundColor: '#1B1B1B',
    color: '#ffffff',
    fontFamily: 'Arial, sans-serif',
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  };

  const titleStyle = {
    color: '#ffcc00',
    fontSize: '32px',
    marginLeft: '280px',
  };

  const linkStyle = {
    color: '#ffffff',
    textDecoration: 'none',
    fontWeight: 'bold',
    transition: 'color 0.3s',
  };

  const servicesStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    justifyContent: 'flex-start',
    marginLeft: '20%',
  };

  const serviceStyle = {
    backgroundColor: '#1e1e1e',
    borderRadius: '10px',
    overflow: 'hidden',
    width: '100%',
    maxWidth: '250px',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.4)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    cursor: 'pointer',
  };

  const serviceHoverEffect = {
    transform: 'scale(1.05)',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.5)',
  };

  const serviceImageStyle = {
    width: '100%',
    height: '150px',
    objectFit: 'cover',
    transition: 'opacity 0.3s',
  };

  const serviceContentStyle = {
    padding: '15px',
    color: '#e0e0e0',
  };

  const serviceTitleStyle = {
    fontSize: '18px',
    marginBottom: '8px',
    fontWeight: '600',
  };

  const serviceTextStyle = {
    fontSize: '14px',
    lineHeight: '1.5',
    color: '#bbbbbb',
  };

  const readMoreLinkStyle = {
    display: 'inline-block',
    color: '#ffcc00',
    textDecoration: 'none',
    fontWeight: 'bold',
    marginTop: '10px',
    transition: 'color 0.3s',
    padding: '8px 10px',
    borderRadius: '5px',
    backgroundColor: '#282828',
  };

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <h1 style={titleStyle}>Other Services</h1>
        <a href="#" style={{ ...linkStyle, ':hover': { color: '#ffcc00' } }}>
          See all
        </a>
      </div>
      <div style={servicesStyle}>
        {[
          {
            title: 'UI/UX Design',
            description: 'Redesign, rebuild, and redefine user experiences with best-in-class design services.',
            image: 'https://storage.googleapis.com/a1aa/image/U3TyrR7jLnaEAZWNe8aJZ2XWVkXfQyrtHybPRndtyyr4OTrTA.jpg',
          },
          {
            title: 'Mobile App Development',
            description: 'Design dynamic mobile apps using cutting-edge technologies for business transformation.',
            image: 'https://storage.googleapis.com/a1aa/image/3eBku8HXofmmepqdEoj5qvqcvhCI5eHSvv91ALkHj5Vo7MtOB.jpg',
          },
          {
            title: 'Digital Marketing',
            description: 'Drive sustainable growth and high website traffic with powerful online tools.',
            image: 'https://storage.googleapis.com/a1aa/image/Bn37BwV40fQbZSo0kHkasEnRfCUI8QLi95dzIrsxEz47OTrTA.jpg',
          },
          {
            title: 'Web Development',
            description: 'Our web experts create bespoke websites that set you apart from the competition.',
            image: 'https://storage.googleapis.com/a1aa/image/EhtLexNIP0VGS6o7KtYLtlKlpZnlkKExTfa54jWfHIgvdmWnA.jpg',
          },
        ].map((service, index) => (
          <div
            key={index}
            style={{
              ...serviceStyle,
              ':hover': serviceHoverEffect,
            }}
          >
            <img src={service.image} alt={service.title} style={serviceImageStyle} />
            <div style={serviceContentStyle}>
              <h2 style={serviceTitleStyle}>{service.title}</h2>
              <p style={serviceTextStyle}>{service.description}</p>
              <a href="#" style={readMoreLinkStyle}>
                Read More
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Combined Page Component
const CombinedPage = () => (
  <div>
    <PageFourCRM />
    <OtherServices />
  </div>
);

export default PageFourCRM;
