import React, { useState, useEffect } from 'react';
import SevenBank from './Sevencare';
import EightBank from './Eightcare';
import Thirdcare from './Thirdcare';
import Fourcare from './Fourcare';
import Fivecare from './Fivecare';
import Sixcare from './Sixcare';

const Healthcare = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div
        style={{
          margin: 0,
          fontFamily: "'Roboto', sans-serif",
          background: '#121212',
          color: '#fff',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: windowWidth < 768 ? 'column' : 'row',
            alignItems: 'flex-start',
            width: '100%',
            maxWidth: '1200px',
            padding: '20px',
            backgroundColor: '#1C1C1C',
            borderRadius: '15px',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
            gap: '30px',
          }}
        >
          {/* Text Section */}
          <div
            style={{
              flex: 1,
              maxWidth: windowWidth < 768 ? '100%' : '60%',
              padding: '0 20px',
            }}
          >
            <h2 style={{ fontSize: '28px', color: '#f57c00', marginBottom: '10px' }}>
              Healthcare
            </h2>
            <h1 style={{ fontSize: '42px', margin: '10px 0', lineHeight: '1.2' }}>
              Healthcare Development Services
            </h1>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: windowWidth < 768 ? '1fr' : 'repeat(3, 1fr)',
                gap: '20px',
                margin: '30px 0',
              }}
            >
              {[
                { icon: 'fa-laptop-code', text: 'Electronic Health Records (EHR)' },
                { icon: 'fa-chalkboard-teacher', text: 'Telemedicine Integration' },
                { icon: 'fa-mobile-alt', text: 'Appointment Scheduling' },
                { icon: 'fa-video', text: 'Insurance Verification' },
                { icon: 'fa-file-alt', text: 'HIPAA Compliance' },
                { icon: 'fa-gamepad', text: 'Patient Portals' },
              ].map((service, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#333',
                    borderRadius: '10px',
                    padding: '20px',
                    textAlign: 'center',
                    color: '#ddd',
                    transition: 'transform 0.3s, color 0.3s',
                    cursor: 'pointer',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.color = '#f57c00';
                    e.currentTarget.style.transform = 'translateY(-5px)';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.color = '#ddd';
                    e.currentTarget.style.transform = 'translateY(0)';
                  }}
                >
                  <i
                    className={`fas ${service.icon}`}
                    style={{
                      fontSize: '40px',
                      marginBottom: '15px',
                      color: '#f57c00',
                    }}
                  ></i>
                  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    {service.text}
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* Form Section */}
          <div
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.85)',
              padding: '20px',
              borderRadius: '8px',
              maxWidth: windowWidth < 768 ? '100%' : '300px',
              width: '100%',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
            }}
          >
            <h2 style={{ color: '#f57c00', fontSize: '20px', marginBottom: '15px' }}>
              Book Free Consultation
            </h2>
            <p style={{ fontSize: '13px', marginBottom: '15px', color: '#ccc' }}>
              Fill Out The Form And Our Experts Will Contact You Within 24hrs.
            </p>
            <form>
              {['Full Name*', 'Email*', 'Phone Number*', 'Company Name'].map(
                (placeholder, index) => (
                  <div key={index} style={{ marginBottom: '10px' }}>
                    <input
                      type="text"
                      placeholder={placeholder}
                      required={placeholder.includes('*')}
                      style={{
                        width: '100%',
                        padding: '8px',
                        border: '1px solid #444',
                        borderRadius: '5px',
                        fontSize: '14px',
                        backgroundColor: '#333',
                        color: '#eee',
                      }}
                    />
                  </div>
                )
              )}
              <div style={{ marginBottom: '10px' }}>
                <textarea
                  placeholder="Your Requirement*"
                  required
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: '1px solid #444',
                    borderRadius: '5px',
                    fontSize: '14px',
                    backgroundColor: '#333',
                    color: '#eee',
                    resize: 'none',
                    height: '80px',
                  }}
                ></textarea>
              </div>
              <button
                type="submit"
                style={{
                  backgroundColor: '#f57c00',
                  color: '#fff',
                  padding: '10px',
                  border: 'none',
                  borderRadius: '5px',
                  fontSize: '14px',
                  cursor: 'pointer',
                  width: '100%',
                  transition: 'background 0.3s',
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = '#e67e22')}
                onMouseOut={(e) => (e.target.style.backgroundColor = '#f57c00')}
              >
                Submit Your Requirement!
              </button>
            </form>
          </div>
        </div>
      </div>
      <Sixcare />
      <Thirdcare />
      <Fourcare />
      <Fivecare />
      
      <EightBank />
    </>
  );
};

export default Healthcare;
