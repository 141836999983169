import React from 'react';

const SixBank = () => {
  const items = [
    {
      title: 'Task-Oriented Approach',
      description:
        'We deliver intuitive, task-focused software solutions that streamline operations and empower teams.',
      technology: 'ALL',
      region: 'India',
      imageUrl:
        'https://images.pexels.com/photos/3183150/pexels-photo-3183150.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    },
    {
      title: 'User-Centric UI/UX',
      description: 'Our designs focus on usability and seamless interactions to enhance the overall user experience.',
      technology: 'ALL',
      region: 'India',
      imageUrl:
        'https://images.pexels.com/photos/4348401/pexels-photo-4348401.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    },
    {
      title: 'Comprehensive Maintenance',
      description: 'We ensure consistent updates and improvements to keep your applications running smoothly.',
      technology: 'ALL',
      region: 'India',
      imageUrl:
        'https://images.pexels.com/photos/442152/pexels-photo-442152.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    },
  ];

  return (
    <div
      style={{
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#000000',
        color: '#fff',
        padding: '0',
        margin: '0',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          padding: '50px 0',
          margin: '0 auto',
          maxWidth: '1200px',
        }}
      >
        <h1
          style={{
            fontSize: '2.5em',
            fontWeight: '700',
            color: '#ffd700',
            letterSpacing: '1px',
            textTransform: 'uppercase',
          }}
        >
          OUR <span style={{ color: '#ff7f00' }}>Expertise</span>
        </h1>
        <p
          style={{
            fontSize: '1.2em',
            lineHeight: '1.6',
            maxWidth: '800px',
            margin: '20px auto',
            color: '#ccc',
          }}
        >
          With a deep commitment to excellence, our expertise ensures that your <span style={{ color: '#ff7f00' }}>digital solutions</span> are effective,
          engaging, and effortlessly managed.
        </p>

        {/* Card Container */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: '30px',
            padding: '0 20px',
            marginTop: '30px',
          }}
        >
          {items.map((item, index) => (
            <div
              key={index}
              style={{
                backgroundColor: '#fff',
                color: '#000',
                borderRadius: '15px',
                width: '100%',
                maxWidth: '350px',
                margin: '0 auto',
                padding: '20px',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                cursor: 'pointer',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.05)';
                e.currentTarget.style.boxShadow = '0 6px 30px rgba(0, 0, 0, 0.2)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
                e.currentTarget.style.boxShadow = '0 4px 20px rgba(0, 0, 0, 0.1)';
              }}
            >
              <img
                alt={`Screenshot of ${item.title}`}
                src={item.imageUrl}
                width="100%"
                height="200"
                style={{
                  borderRadius: '10px',
                }}
              />
              <h2
                style={{
                  fontSize: '1.4em',
                  marginTop: '20px',
                  fontWeight: '600',
                  color: '#ff7f00',
                }}
              >
                {item.title}
              </h2>
              <p
                style={{
                  fontSize: '1em',
                  color: '#333',
                  marginTop: '10px',
                }}
              >
                {item.description}
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '20px',
                  fontSize: '0.9em',
                  color: '#ff7f00',
                }}
              >
                <div>
                  <strong>Technology</strong>
                  <br />
                  {item.technology}
                </div>
                <div>
                  <strong>Region</strong>
                  <br />
                  {item.region}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SixBank;
