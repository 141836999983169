import React, { useState } from 'react';
import './Navbar.css';
import kotiboxlogo from '../imgvdo/kotiboxlogo.png';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isServicesOpen, setIsServicesOpen] = useState(false);
    const [isIndustriesOpen, setIsIndustriesOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleServices = () => {
        setIsServicesOpen(!isServicesOpen);
        setIsIndustriesOpen(false);
    };

    const toggleIndustries = () => {
        setIsIndustriesOpen(!isIndustriesOpen);
        setIsServicesOpen(false);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <div className="navbar">
            <div className="logo">
                <a href="/#">
                    <img src={kotiboxlogo} alt="KotiBox Logo" className="logo-img" />
                </a>
            </div>
            <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
                <a href="./Aboutt" onClick={closeMenu}>About</a>
                <a href="#" onClick={toggleServices} className={isServicesOpen ? 'dropdown-active' : ''}>Services</a>
                {isServicesOpen && (
                    <div className="dropdown services">
                        <a href="./SoftwareDevelopment" onClick={closeMenu}>Software Development</a>
                        <a href="./ECommerceServices" onClick={closeMenu}>eCommerce Services</a>
                        <a href="./DigitalMarketing" onClick={closeMenu}>Digital Marketing</a>
                        <a href="./CRMDevelopment" onClick={closeMenu}>CRM Development</a>
                        <a href="./FullStack" onClick={closeMenu}>Full Stack Development</a>
                        <a href="./ContentMarketing" onClick={closeMenu}>Content Marketing</a>
                        <a href="./ArtificialIntelligence" onClick={closeMenu}>Artificial Intelligence</a>
                        <a href="./AppDevelopment" onClick={closeMenu}>App Development</a>
                        <a href="./Blockchain" onClick={closeMenu}>Blockchain</a>
                        <a href="./ERPDevelopment" onClick={closeMenu}>ERP Development</a>
                        <a href="./WebsiteDevelopment" onClick={closeMenu}>Website Development</a>
                        <a href="./UIUXAndVideography" onClick={closeMenu}>UI/UX & Videography</a>
                    </div>
                )}
                <a href="#" onClick={toggleIndustries} className={isIndustriesOpen ? 'dropdown-active' : ''}>Industries</a>
                {isIndustriesOpen && (
                    <div className="dropdown industries">
                        <a href="./BankingAndFinance" onClick={closeMenu}>Banking & Finance</a>
                        <a href="./EcommerceSolutions" onClick={closeMenu}>Ecommerce Solutions</a>
                        <a href="./FoodAndRestaurants" onClick={closeMenu}>Food & Restaurants</a>
                        <a href="./Gaming" onClick={closeMenu}>Gaming</a>
                        <a href="./Healthcare" onClick={closeMenu}>Healthcare</a>
                        <a href="./MediaAndPublication" onClick={closeMenu}>Media & Publication</a>
                        <a href="./Startups" onClick={closeMenu}>Startups</a>
                        <a href="./TourAndTravel" onClick={closeMenu}>Tour & Travel</a>
                        <a href="./RealState" onClick={closeMenu}>Real Estate</a>
                    </div>
                )}
                <a href="./Career" className="active" onClick={closeMenu}>Career</a>
                <a href="./HireDeveloper" onClick={closeMenu}>Hire Developer</a>
                <a href="./Contact" className="contact-btn" onClick={closeMenu}>Contact us</a>
            </div>
            <div className="hamburger" onClick={toggleMenu}>
                <i className="fas fa-bars"></i>
            </div>
        </div>
    );
};

export default Navbar;
