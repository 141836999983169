import React from 'react';
import './Service.css'; // External CSS file for styling

const services = [
  {
    id: 1,
    image: 'https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExaG1qYXFnOTF5NWQwYjg1dHIyZjV4aWMxZml5aTMwb2wwbWR6Z2k0ZiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/L8K62iTDkzGX6/giphy.webp',
    title: 'Web Development',
    description: 'We prioritize customer happiness and creativity while creating unique, responsive websites.',
  },
  {
    id: 2,
    image: 'https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExOWlwNGowejUwZG5xeW9iaDBjM3VpcGNiNTJtYXBvMW14Mmdpd3ZxeSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/8VkgrPdxMh0oo/giphy.webp',
    title: 'Mobile App Development',
    description: 'We craft intuitive mobile apps with stunning design and seamless functionality.',
  },
  {
    id: 3,
    image: 'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExZHMzZXIydjlxYWR0bXVqemt5aHZ5aHBkNGs3d2tpZ2Q0a3RmN3FpbCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/S93WQ0wrqd8AupBTuh/giphy.webp',
    title: 'UI/UX Design',
    description: 'Our UI/UX designs are future-ready and provide your business a competitive advantage.',
  },
  {
    id: 4,
    image: 'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExajV0dDRsMDJkeW16ZTUzdTVpb3J3cjNneHlrOWdxYmxzZXVlODNqaSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/XyJPNKBskIDWR3Md8K/giphy.webp',
    title: 'Digital Marketing',
    description: 'We provide customized tactics for attracting quality leads and growing your business.',
  },
  {
    id: 5,
    image: 'https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExcDV0NW1zMHpoNXhqeHMyNXZrNHFwb3JvYXdveWJzMDI5NThuMWNjOSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/HAlkLKMwmaiqRetunU/giphy.webp',
    title: 'eCommerce Development',
    description: 'We create scalable and user-friendly eCommerce solutions.',
  },
  {
    id: 6,
    image: 'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExZmxvcjAxaHU4b2dwNmUwNng2ZWx4bzE0Yms2cGlpb3M2eXUzNGd6aCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/GVZ8CcJFQAjhdaA4Ro/giphy.webp',
    title: 'CRM Development',
    description: 'Our unique CRM systems boost productivity and improve user experience.',
  },
  {
    id: 7,
    image: 'https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExbnBsZGE3d3dxZmNub2YwZDgybjA4d2k0bHRyd3R0aHBoMmw2Znc5ZyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/hAudXTEX1tKI34vz7i/giphy.webp',
    title: 'ERP Solution',
    description: 'We offer bespoke enterprise solutions to streamline operations.',
  },
  {
    id: 8,
    image: 'https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExajBqMHdwOThhZm1peW03dXliM2lkNXNiNjdhM3lhdHd5MnR4bWkwdCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/2ikwIgNrmPZICNmRyX/giphy.webp',
    title: 'Fullstack Development',
    description: 'With more than ten years of experience, we offer full stack development.',
  },
  {
    id: 9,
    image: 'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExYWFsbWR6NXAwZGtrd3R5eTc0cGJwdmU3dmhxcDZyaWdqODZndDR2dyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/b7lp44pNiRqsU/giphy.webp',
    title: 'Game Development',
    description: 'We transform your ideas into groundbreaking game experiences.',
  },
  {
    id: 10,
    image: 'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExYWNic3Q5cmR4eXk2eDczMGd6OGNzZGV2dXUwcndsMjh2bHppNWhoZCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/l3q2EOu4nu1D8uJKU/giphy.webp',
    title: 'Blockchain Development',
    description: 'We provide blockchain technologies that increase digital security and transparency.',
  },
  {
    id: 11,
    image: 'https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExazg4NzY2M2h4NWsxcDh5NzBzcGFyOG84aTU3cXR6cjFoaWo0cHJydyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/8iALpBNdW0mFMdytRE/giphy.webp',
    title: 'Cloud Computing',
    description: 'We offer scalable cloud solutions to support your corporate infrastructure.',
  },
  {
    id: 12,
    image: 'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExYWZuNTRzYzBkdnAxcGd2NTg4bzhwZzk4cHBwbnExajc2a2gyeXFpbiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/5k5vZwRFZR5aZeniqb/giphy.webp',
    title: 'AI Solutions',
    description: 'We create AI-powered solutions for boosting your business processes.',
  },
];

const Service = () => {
  return (
    <div className="service-container">
      <h1 className="service-heading">
      Fusing Innovation and Excellence with{' '}
        <span className="highlighted-text">Technology</span>
      </h1>
      <p className="service-description">
      Discover our diverse services crafted to drive your business growth with efficiency and impact.
      </p>

      <div className="card-grid">
        {services.map((service, index) => (
          <div
            className={`card ${index % 2 === 0 ? 'dark-card' : 'dark-card'}`} 
            key={service.id}
          >
            <img src={service.image} alt={service.title} className="card-img" />
            <div className="card-content">
              <h2 className="card-title">{service.title}</h2>
              <p className="card-text">{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Service;
