import React, { useEffect, useState } from 'react';

const Banner = () => {
  const [paddingTop, setPaddingTop] = useState(0);
  const [marginLeftRight, setMarginLeftRight] = useState('8%'); // Default 8% margin

  useEffect(() => {
    // Example: Get the padding from a parent element (could be header height or other)
    const parentElement = document.querySelector('header'); // or any upper page element
    if (parentElement) {
      const computedStyles = window.getComputedStyle(parentElement);
      setPaddingTop(computedStyles.paddingTop);
      setMarginLeftRight('20%'); // You can adjust this dynamically based on screen width or conditions
    }
  }, []);

  const whiteSectionStyle = {
    backgroundColor: 'white',
    padding: '15px 0', // Big padding for the white section above and below
  };

  const bannerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundImage: 'url("https://images.pexels.com/photos/943096/pexels-photo-943096.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: `${paddingTop || '40px'} 0`,         // Dynamic padding for top and bottom
    margin: `0 ${marginLeftRight}`,           // Dynamic margin for left and right
    border: '2px solid #fff',  // Border around the banner
    borderRadius: '15px',
    color: 'white',
    flexWrap: 'wrap' // Makes the banner responsive by wrapping content when needed
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '30px', // Moves text to the right by 30px
  };

  const headingStyle = {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    margin: 0,
  };

  const paragraphStyle = {
    fontSize: '1.2rem',
    marginTop: '10px',
  };

  const buttonStyle = {
    backgroundColor: '#ff7a29', /* Orange color */
    color: 'white',
    textDecoration: 'none',
    padding: '15px 30px',
    borderRadius: '6px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    transition: 'background-color 0.3s ease',
    cursor: 'pointer',
    marginRight: '30px', // Moves button to the left by 30px
  };

  const arrowStyle = {
    marginLeft: '10px',
  };

  const buttonHoverStyle = {
    backgroundColor: '#e66a00',
  };

  // Media queries for responsiveness
  const responsiveBannerStyle = {
    ...bannerStyle,
    '@media (max-width: 768px)': {
      flexDirection: 'column', // Stacks content on smaller screens
      margin: '0 5%', // Adjusts margins on smaller screens
      textAlign: 'center',
    },
    '@media (max-width: 576px)': {
      padding: '20px 0', // Reduce padding on small screens
    },
  };

  const responsiveHeadingStyle = {
    ...headingStyle,
    '@media (max-width: 768px)': {
      fontSize: '2rem', // Smaller font size on medium screens
    },
    '@media (max-width: 576px)': {
      fontSize: '1.5rem', // Even smaller font size on small screens
    },
  };

  const responsiveParagraphStyle = {
    ...paragraphStyle,
    '@media (max-width: 768px)': {
      fontSize: '1rem', // Adjust font size for medium screens
    },
    '@media (max-width: 576px)': {
      fontSize: '0.9rem', // Smaller font size for small screens
    },
  };

  const responsiveButtonStyle = {
    ...buttonStyle,
    '@media (max-width: 768px)': {
      margin: '20px auto', // Center the button on smaller screens
      padding: '12px 24px', // Reduce button padding
    },
    '@media (max-width: 576px)': {
      width: '100%', // Make button full width on small screens
      justifyContent: 'center', // Center button text
    },
  };

  return (
    <div>
      {/* Upper White Section */}
      <div style={whiteSectionStyle}></div>

      {/* Banner Section */}
      <div style={responsiveBannerStyle}>
        <div style={contentStyle}>
          <h1 style={responsiveHeadingStyle}>From Code to Success <span style={arrowStyle}>↗</span></h1>
          <p style={responsiveParagraphStyle}>We Build More Than Software.</p>
        </div>
        <a
          href="Contact"
          style={responsiveButtonStyle}
          onMouseEnter={(e) => (e.target.style.backgroundColor = buttonHoverStyle.backgroundColor)}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#ff7a29')}
        >
          Discuss Your Requirement! <span style={arrowStyle}>→</span>
        </a>
      </div>

      {/* Lower White Section */}
      <div style={whiteSectionStyle}></div>
    </div>
  );
};

export default Banner;
