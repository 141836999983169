import React from 'react';

const MobileAppDevelopment = () => {
  const styles = {
    body: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: '#121212', // Dark mode background
      color: '#ffffff',
      padding: 0,
      margin: 0,
    },
    container: {
      padding: '50px 15px',
    },
    headerSpan: {
      color: '#f97316',
      fontSize: '14px',
      fontWeight: 700,
      textAlign: 'center',
      display: 'block',
      marginBottom: '10px',
    },
    headerH1: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: 1.3,
      textAlign: 'center',
      marginBottom: '30px',
    },
    serviceCard: {
      backgroundColor: '#1e1e1e', // Dark card background
      borderRadius: '12px',
      padding: '20px',
      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      margin: '0 10%', // 10% margin on left and right
      maxWidth: '80%', // Restrict card size
    },
    serviceCardHover: {
      transform: 'translateY(-10px)',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
    },
    icon: {
      fontSize: '40px',
      color: '#f97316',
    },
    serviceCardH3: {
      fontSize: '20px',
      fontWeight: 700,
      margin: '20px 0 10px',
      textAlign: 'center',
      color: '#ffffff',
    },
    serviceCardP: {
      fontSize: '14px',
      lineHeight: 1.6,
      color: '#b0b0b0',
      textAlign: 'justify',
    },
    highlightIcon: {
      fontSize: '14px',
      color: '#f97316',
      marginRight: '10px',
    },
    highlightSpan: {
      backgroundColor: '#f97316',
      color: '#ffffff',
      padding: '5px 10px',
      borderRadius: '20px',
      fontSize: '12px',
    },
    arrowIcon: {
      fontSize: '20px',
      color: '#f97316',
    },
  };

  const serviceData = [
    {
      icon: 'fas fa-mobile-alt',
      title: 'Cross-Platform App Development',
      description:
        'Build apps that work flawlessly on iPhones, iPads, Android phones, and tablets. We leverage React Native, Flutter, and Xamarin to build a single app that runs smoothly on multiple devices.',
      highlights: [
        'Cross-Device Functionality',
        'Cutting-Edge Development Tools',
        'Reduced Development Time and Cost',
        'Wider Market Reach',
      ],
    },
    {
      icon: 'fas fa-tools',
      title: 'App Maintenance and Support',
      description:
        'Keep your app running great with our maintenance and support services. We handle updates, security patches, and performance tweaks to make sure your app is always working at its best.',
      highlights: [
        'Long-Term App Performance',
        'Improved User Engagement',
        'Performance Optimisation',
        'Regular Security Patches',
      ],
    },
    {
      icon: 'fas fa-lightbulb',
      title: 'Prototyping and MVP Development',
      description:
        'Get your app idea in front of real users fast with rapid prototyping and Minimum Viable Product (MVP) development. This lets you gather valuable feedback and refine your features before investing a lot of time and money.',
      highlights: [
        'Rapid Idea Validation',
        'Minimum Viable Product',
        'Gather Valuable Feedback',
        'Reduced Development Risk',
      ],
    },
    {
      icon: 'fas fa-laptop',
      title: 'E-commerce Mobile App Development',
      description:
        'Get a user-friendly mobile shopping app for your e-commerce store. We focus on clear interfaces, secure payment options, and a smooth shopping experience to keep your customers happy and coming back for more.',
      highlights: [
        'Increased Sales',
        'Brand Recognition',
        'Customer Engagement',
        'Secure Transactions',
      ],
    },
    {
      icon: 'fas fa-rocket',
      title: 'App Store Optimization (ASO)',
      description:
        'Imagine your app being easily found by millions of potential users. Through ASO, we make this a reality. We refine your app’s visibility in the app store, enhance user engagement, and increase organic downloads.',
      highlights: [
        'High Visibility',
        'Organic Downloads',
        'Competitive Analysis',
        'Review Management',
      ],
    },
    {
      icon: 'fas fa-wrench',
      title: 'UI/UX Design for Mobile Apps',
      description:
        'Craft intuitive interfaces that delight users with stunning visuals and effortless navigation. We prioritize user-centered design to make your app a joy to use, boosting engagement and keeping you ahead of the competition.',
      highlights: [
        'Intuitive Interfaces',
        'Visually Appealing Design',
        'Seamless Navigation',
        'User-friendly app',
      ],
    },
    {
      icon: 'fas fa-network-wired',
      title: 'IoT (Internet of Things) Integration',
      description:
        'Connect your app to smart devices and create powerful solutions! We can integrate your app with IoT (Internet of Things) devices, like those used in healthcare, home automation, or even industrial settings.',
      highlights: [
        'Connect to Smart Devices',
        'Advanced Data Analytics & Management',
        'Improved User Experience',
        'Streamlined Operations',
      ],
    },
    {
      icon: 'fas fa-camera-retro',
      title: 'AR and VR Apps',
      description:
        'Develop immersive AR/VR applications for gaming, education, retail, and real estate. Utilizing cutting-edge technologies, we create immersive experiences that blend virtual elements with the real world.',
      highlights: [
        'Interactive & Engaging Content',
        'Innovative Product Visualisation',
        'Captivating AR/VR applications',
        'New Marketing & Sales Opportunities',
      ],
    },
    {
      icon: 'fas fa-wrench',
      title: 'Wearable Application Development',
      description:
        'Want to expand your reach to smartwatches and other wearables? Our developers are pros at creating intuitive companion apps that offer an immersive user experience, perfectly complementing your main app.',
      highlights: [
        'Real-Time Data Access',
        'User-Friendly Interfaces',
        'Cross-Platform Support',
        'Custom Wearable Solutions',
      ],
    },
  ];

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div>
          <span style={styles.headerSpan}>SERVICES</span>
          <h1 style={styles.headerH1}>
            Our Expertise In Mobile Application Development
          </h1>
        </div>
        <div className="row g-4">
          {serviceData.map((service, index) => (
            <div className="col-12 col-md-6 col-lg-4" key={index}>
              <div
                style={styles.serviceCard}
                onMouseOver={(e) =>
                  Object.assign(e.currentTarget.style, styles.serviceCardHover)
                }
                onMouseOut={(e) =>
                  Object.assign(e.currentTarget.style, {
                    transform: 'translateY(0)',
                    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                  })
                }
              >
                <div className="text-center mb-3" style={styles.icon}>
                  <i className={service.icon}></i>
                </div>
                <h3 style={styles.serviceCardH3}>{service.title}</h3>
                <p style={styles.serviceCardP}>{service.description}</p>
                <div>
                  {service.highlights.map((highlight, idx) => (
                    <div key={idx} className="d-flex align-items-center mb-2">
                      <i
                        className="fas fa-check"
                        style={styles.highlightIcon}
                      ></i>
                      <span style={styles.highlightSpan}>{highlight}</span>
                    </div>
                  ))}
                </div>
                <div className="text-end">
                  <i className="fas fa-arrow-right" style={styles.arrowIcon}></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileAppDevelopment;
