import React, { useState } from 'react';
import Devsecond from './Devsecond';
import Devthree from './Devthree';
import Devfour from './Devfour';
import Devfive from './Devfive';
import Devsix from './Devsix';
import Devseven from './Devseven';

const Counter = ({ target }) => {
  const [count, setCount] = useState(0);

  React.useEffect(() => {
    let start = 0;
    const end = parseInt(target);
    if (start === end) return;

    const duration = 2000;
    const incrementTime = Math.abs(Math.floor(duration / end));

    const timer = setInterval(() => {
      start += 1;
      setCount(start);
      if (start === end) clearInterval(timer);
    }, incrementTime);

    return () => clearInterval(timer);
  }, [target]);

  return <span>{count}+</span>;
};

const HireDeveloper = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleButtonClick = () => {
    setIsFormVisible((prevState) => !prevState); // Toggle form visibility
  };

  return (
    <>
      <div
        style={{
          fontFamily: "'Poppins', sans-serif",
          backgroundColor: '#000000',
          color: '#ffffff',
          textAlign: 'center',
          padding: '60px 15px',
        }}
      >
        {/* Centralized Heading Section */}
        <div style={{ margin: '0 auto', maxWidth: '800px', padding: '40px 20px' }}>
          <h1
            style={{
              fontSize: '48px',
              fontWeight: '700',
              marginBottom: '20px',
              lineHeight: '1.3',
            }}
          >
            Hire Expert Developers
          </h1>

          {/* Conditionally Render Text or Form */}
          {!isFormVisible && (
            <p
              style={{
                fontSize: '20px',
                fontWeight: '400',
                color: '#bbbbbb',
                marginBottom: '30px',
                lineHeight: '1.6',
                transition: 'opacity 0.5s ease-out',
              }}
            >
              Are you ready to transform your idea into a sophisticated, high-performing application or
              website that captivates users and delivers an exceptional experience? Our team of expert
              developers crafts intuitive, feature-rich, and scalable solutions, leveraging cutting-edge
              technologies to bring your vision to life with precision and excellence.
            </p>
          )}

          {/* Form */}
          {isFormVisible && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '40px 20px',
                backgroundColor: '#111111',
                marginTop: '40px',
                borderRadius: '10px',
                transition: 'opacity 0.5s ease-in-out',
              }}
            >
              <form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  width: '100%',
                  maxWidth: '600px',
                  animation: 'slideIn 0.5s ease-out',
                }}
              >
                <input
                  type="text"
                  placeholder="Your Name"
                  style={{
                    padding: '10px',
                    fontSize: '16px',
                    border: '1px solid #f0c040',
                    borderRadius: '5px',
                    backgroundColor: '#333',
                    color: '#fff',
                  }}
                />
                <input
                  type="tel"
                  placeholder="Your Phone Number"
                  style={{
                    padding: '10px',
                    fontSize: '16px',
                    border: '1px solid #f0c040',
                    borderRadius: '5px',
                    backgroundColor: '#333',
                    color: '#fff',
                  }}
                />
                <input
                  type="email"
                  placeholder="Your Email"
                  style={{
                    padding: '10px',
                    fontSize: '16px',
                    border: '1px solid #f0c040',
                    borderRadius: '5px',
                    backgroundColor: '#333',
                    color: '#fff',
                  }}
                />
                <input
                  type="text"
                  placeholder="Subject"
                  style={{
                    padding: '10px',
                    fontSize: '16px',
                    border: '1px solid #f0c040',
                    borderRadius: '5px',
                    backgroundColor: '#333',
                    color: '#fff',
                  }}
                />
                <textarea
                  placeholder="Your Message"
                  rows="4"
                  style={{
                    padding: '10px',
                    fontSize: '16px',
                    border: '1px solid #f0c040',
                    borderRadius: '5px',
                    backgroundColor: '#333',
                    color: '#fff',
                  }}
                ></textarea>
                <button
                  type="submit"
                  style={{
                    padding: '12px 30px',
                    backgroundColor: '#f0c040',
                    border: 'none',
                    color: '#000000',
                    fontSize: '16px',
                    fontWeight: '500',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s, color 0.3s',
                  }}
                >
                  Submit
                </button>
              </form>
            </div>
          )}

          <button
            onClick={handleButtonClick}
            style={{
              display: 'inline-block',
              padding: '12px 30px',
              backgroundColor: 'transparent',
              border: '2px solid #f0c040',
              color: '#f0c040',
              fontSize: '16px',
              fontWeight: '500',
              textDecoration: 'none',
              borderRadius: '5px',
              transition: 'background-color 0.3s, color 0.3s',
            }}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = '#f0c040';
              e.target.style.color = '#000000';
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = 'transparent';
              e.target.style.color = '#f0c040';
            }}
          >
            {isFormVisible ? 'Back to Text' : 'Hire ReactJS Developer Now'}
          </button>
        </div>
      </div>

      {/* Additional Components */}
      <Devfour />
      <Devsix />
      <Devseven />
      <Devfive />
      <Devsecond />
      <Devthree />
    </>
  );
};

export default HireDeveloper;
