import React, { useState } from "react";
import styled from "styled-components";

// Data for developers
const developerData = [
  {
    id: 1,
    title: "Hire DotNet Developers",
    description:
      "Our skilled .NET developers craft high-performance, cross-platform applications that provide the same functionality and benefits as native software. Hire our .NET developers for top-tier solutions tailored to meet your business objectives with seamless scalability.",
    icon:
      "https://img.icons8.com/?size=100&id=z2gsPTsahvBW&format=png&color=000000",
  },
  {
    id: 2,
    title: "Hire PHP Developers",
    description:
      "Our PHP developers specialize in creating dynamic, secure, and scalable web applications. With a focus on performance and flexibility, hire our PHP experts to build solutions that meet your business goals and enhance your digital presence.",
    icon:
      "https://img.icons8.com/?size=100&id=pKaVdzbCJGgA&format=png&color=000000",
  },
  {
    id: 3,
    title: "Hire Python Developers",
    description:
      "Our React Native developers craft cross-platform mobile applications that offer a native-like experience with a single codebase. Hire our experts to build high-performance, scalable mobile apps that work seamlessly across both iOS and Android.",
    icon:
      "https://img.icons8.com/?size=100&id=71256&format=png&color=40C057",
  },
  {
    id: 4,
    title: "React Native Developer",
    description:
      "Our Python developers create scalable, efficient, and highly functional applications across diverse industries. Hire our experts to develop data-driven, high-performance solutions that drive innovation and streamline your operations.",
    icon:
      "https://img.icons8.com/?size=100&id=58811&format=png&color=40C057",
  },
  {
    id: 5,
    title: "ReactJS Developer",
    description:
      "Our WordPress developers create visually stunning, responsive websites with advanced functionality. Hire our WordPress experts to build tailored solutions that offer seamless content management and a smooth user experience.",
    icon:
      "https://img.icons8.com/?size=100&id=13441&format=png&color=000000",
  },
  {
    id: 6,
    title: "WordPress Developer",
    description:
      "Our Python developers create scalable, efficient, and highly functional applications across diverse industries. Hire our experts to develop data-driven, high-performance solutions that drive innovation and streamline your operations.",
    icon:
      "https://img.icons8.com/?size=100&id=101337&format=png&color=228BE6",
  },
  {
    id: 7,
    title: "iOS Developer",
    description:
      "Our iOS developers create sleek, high-quality mobile applications for Apple devices, designed to deliver exceptional performance and user experiences. Trust our iOS experts to develop innovative solutions that perfectly align with your brand and user needs.",
    icon:
      "https://img.icons8.com/?size=100&id=uoRwwh0lz3Jp&format=png&color=000000",
  },
  {
    id: 8,
    title: "Laravel Developer",
    description:
      "Our Laravel developers specialize in building scalable, secure, and feature-rich web applications using this powerful PHP framework. Hire our experts to craft custom solutions that ensure high performance and seamless integration for your business.",
    icon:
      "https://img.icons8.com/?size=100&id=7vdHawe2VPlT&format=png&color=000000",
  },
  {
    id: 9,
    title: "Magento Developer",
    description:
      "Our Magento developers create customized, scalable e-commerce platforms that enhance user experiences and maximize business potential. Leverage our expertise to build secure, high-performance online stores that drive growth and increase conversions.",
    icon:
      "https://img.icons8.com/?size=100&id=wO7uLQ2bXGP-&format=png&color=000000",
  },
  {
    id: 10,
    title: "Node.js Developer",
    description:
      "Our Node.js developers build fast, scalable, and real-time applications that deliver exceptional performance. Hire our experts to create dynamic back-end systems that power your business with efficiency, speed, and reliability.",
    icon:
      "https://img.icons8.com/?size=100&id=54087&format=png&color=000000",
  },
  {
    id: 11,
    title: "AngularJS Developer",
    description:
      "Our AngularJS developers specialize in creating dynamic, responsive web applications with cutting-edge features. Hire our experts to deliver high-performance solutions that offer a seamless user experience and align perfectly with your business objectives.",
    icon:
      "https://img.icons8.com/?size=100&id=71257&format=png&color=000000",
  },
  {
    id: 12,
    title: "Java Developer",
    description:
      "Our proficient Java developers design secure, scalable, and high-performance applications that meet your business needs. Leverage our Java expertise to build reliable and efficient solutions that ensure seamless user engagement and long-term success.",
    icon:
      "https://img.icons8.com/?size=100&id=lTKW3iI3wIT0&format=png&color=000000",
  },
  {
    id: 13,
    title: "Full Stack Developer",
    description:
      "Our full-stack developers possess the expertise to build end-to-end solutions, from front-end interfaces to back-end systems. Leverage their versatile skills to create dynamic, high-performing applications that meet all your technical and business requirements.",
    icon:
      "https://img.icons8.com/?size=100&id=21789&format=png&color=000000",
  },
  {
    id: 14,
    title: "Android Developer",
    description:
      "Our expert Android developers build robust, user-friendly mobile applications that deliver exceptional performance and functionality. Hire our Android developers to create innovative apps that elevate user experiences and drive business growth.",
    icon:
      "https://img.icons8.com/?size=100&id=yhLahGmmkw2c&format=png&color=000000",
  },
  {
    id: 15,
    title: "Blockchain Developer",
    description:
      "At Kotibox, our blockchain developers harness the power of decentralized technologies to build secure, transparent, and scalable solutions. Trust our blockchain experts to create innovative applications that drive transformation and unlock new business opportunities.",
    icon:
      "https://img.icons8.com/?size=100&id=p3MA8DtJTjlg&format=png&color=000000",
  },
];

// Main DevSix Component
const Devsix = () => {
  return (
    <Container>
      <h1>
        Hire Industry-Leading <Highlight>Developers</Highlight> Quickly
      </h1>
      <h3>Choose from our expert developers for your next big project</h3>
      <CardGrid>
        {developerData.map((dev) => (
          <DeveloperCard key={dev.id} data={dev} />
        ))}
      </CardGrid>
    </Container>
  );
};

// Developer Card Component
const DeveloperCard = ({ data }) => {
  const [showForm, setShowForm] = useState(false);

  return (
    <Card>
      <img src={data.icon} alt={data.title} />
      <h2>{data.title}</h2>
      <p>{data.description}</p>
      <ButtonWrapper>
        <Button onClick={() => setShowForm(!showForm)}>Hire</Button>
      </ButtonWrapper>
      {showForm && (
        <Form>
          <h3>Fill Your Requirements</h3>
          <input type="text" placeholder="Name" required />
          <input type="email" placeholder="Email" required />
          <input type="text" placeholder="Phone Number" required />
          <input type="text" placeholder="Subject" required />
          <textarea placeholder="Requirement/Message" rows="4" required></textarea>
          <SubmitButton>Submit</SubmitButton>
        </Form>
      )}
    </Card>
  );
};

// Styled Components
const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  text-align: center;

  h1 {
    font-size: 2.5rem;
    color: #0000000;
    margin-bottom: 10px;
    font-weight: bold;
  }

  h3 {
    font-size: 1.2rem;
    color: #e0b030;
    margin-bottom: 30px;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1rem;
    }
  }
`;

const Highlight = styled.span`
  color: #ff5722;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
`;

const Card = styled.div`
  background: #1c1c1c;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  img {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: 10px;
  }

  p {
    font-size: 0.9rem;
    color: #ffffff;
    text-align: center;
    flex: 1;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

const Button = styled.button`
  background-color: #000;
  color: #fff;
  font-family: system-ui, sans-serif;
  font-size: 0.9rem;
  padding: 0.8rem 2rem;
  border-radius: 30px;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #f48120;
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
`;

const Form = styled.div`
  background: #282828;
  padding: 20px;
  border-radius: 8px;
  margin-top: 15px;
  width: 100%;

  h3 {
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: 10px;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background: #1c1c1c;
    color: #fff;
  }

  input::placeholder,
  textarea::placeholder {
    color: #888;
  }
`;

const SubmitButton = styled.button`
  background-color: #f48120;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ff5722;
  }
`;

export default Devsix;
