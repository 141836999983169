import React from 'react';
import Navbar from './components/Home/Navbar';
import HomePage from './components/Home/HomePage';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Correct import
import './App.css';
import WebsiteDevelopment from './components/Service Page/services/WebsiteDevelopment.js';
import First from './components/Industries/Food/FoodAndRestaurants';
import HireDeveloper from './components/Hiredeveloper/Reactdeveloper/HireDeveloper';
import CareerPage from './components/Career';
import Contact from './components/Contact';
import About from './components/Home/About';
import AppDevelopment from './components/Service Page/App/AppDevelopment.js';
import DigitalMarketing from './components/Service Page/Digital/DigitalMarketing.js';
import ArtificialIntelligence from './components/Service Page/Artificial/ArtificialIntelligence.js';
import ContentMarketing from './components/Service Page/Content/ContentMarketing.js';
import Blockchain from './components/Service Page/BlockChain/Blockchain.js';
import CRMDevelopment from './components/Service Page/CRM/CRMDevelopment.js';
import ERPDevelopment from './components/Service Page/ERP Development/ERPDevelopment.js';
import FullStack from './components/Service Page/Full Stack/FullStack.js';
import SoftwareDevelopment from './components/Service Page/Software Development/SoftwareDevelopment.js';
import UIUXAndVideography from './components/Service Page/UIUX Development/UIUXAndVideography.js';
import TourAndTravel from './components/Industries/Tour/TourAndTravel';
import MediaAndPublication from './components/Industries/Media/MediaAndPublication';
import RealState from './components/Industries/Real State/RealState.js';
import Gaming from './components/Industries/Game/Gaming';
import Startups from './components/Industries/Startup/Startups';
import BankingAndFinance from './components/Industries/Bank/BankingAndFinance';
import Healthcare from './components/Industries/Healthcare/Healthcare';
import ECommerceServices from './components/Service Page/ECommerce/ECommerceServices.js';
import FoodAndRestaurants from './components/Industries/Food/FoodAndRestaurants';
import CustomFooter from './components/Home/CustomFooter .js';
import EcommerceSolutions from './components/Industries/ECOM/EcommerceSolutions.js';
import Aboutt from './components/Home/Aboutt.js';
import OurStory from './components/Home/OurStory.js';

function App() {
  return (
    <Router> {/* Use Router instead of BrowserRouter as an alias */}
      <Navbar />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/WebsiteDevelopment' element={<WebsiteDevelopment />} />
        <Route path='/AppDevelopment' element={<AppDevelopment />} />
        <Route path='/DigitalMarketing' element={<DigitalMarketing />} />
        <Route path='/ArtificialIntelligence' element={<ArtificialIntelligence />} />
        <Route path='/ContentMarketing' element={<ContentMarketing />} />
        <Route path='/Blockchain' element={<Blockchain />} />
        <Route path='/CRMDevelopment' element={<CRMDevelopment />} />
        <Route path='/ECommerceServices' element={<ECommerceServices />} />
        <Route path='/ERPDevelopment' element={<ERPDevelopment />} />
        <Route path='/FullStack' element={<FullStack />} />
        <Route path='/SoftwareDevelopment' element={<SoftwareDevelopment />} />
        <Route path='/UIUXAndVideography' element={<UIUXAndVideography />} />
        <Route path='/TourAndTravel' element={<TourAndTravel />} />
        <Route path='/MediaAndPublication' element={<MediaAndPublication />} />
        <Route path='/RealState' element={<RealState />} />
        <Route path='/Gaming' element={<Gaming />} />
        <Route path='/Startups' element={<Startups />} />
        <Route path='/EcommerceSolutions' element={<EcommerceSolutions/>}/>
        <Route path='/BankingAndFinance' element={<BankingAndFinance />} />
        <Route path='/Healthcare' element={<Healthcare />} />
        <Route path='/FoodAndRestaurants' element={<FoodAndRestaurants/>} />
        <Route path='/first' element={<First />} />
        <Route path='/HireDeveloper' element={<HireDeveloper />} />
        <Route path='/Career' element={<CareerPage />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/About' element={<About />} />
        <Route path='/Aboutt' element={<Aboutt/>}/>
        <Route path='/OurStory' element={<OurStory/>}/>
       
      </Routes>
      <CustomFooter />
    </Router>
  );
}

export default App;
