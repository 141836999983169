import React, { useState } from 'react';

function PageThreeDigital() {
  // Technologies Section
  const [activeTab, setActiveTab] = useState('frontend');

  const showContent = (tab) => {
    setActiveTab(tab);
  };

  const technologiesContainerStyle = {
    backgroundColor: '#121212',
    color: '#ffffff',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    padding: '50px 0',
    margin: 0,
  };

  const titleStyle = {
    fontSize: '36px',
    letterSpacing: '5px',
    marginBottom: '50px',
  };

  const tabStyle = (isActive) => ({
    padding: '10px 20px',
    cursor: 'pointer',
    margin: '0 10px',
    color: isActive ? '#ffcc00' : '#ffffff',
    fontWeight: isActive ? 'bold' : 'normal',
    borderBottom: isActive ? '2px solid #ffcc00' : 'none',
    transition: 'color 0.3s ease',
  });

  const logoBoxStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  };

  const itemStyle = {
    width: '150px',
    margin: '20px',
    textAlign: 'center',
    transition: 'transform 0.3s ease',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '10px',
    backgroundColor: '#2a2a2a',
    padding: '10px',
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.style.transform = 'scale(1.05)';
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.transform = 'scale(1)';
  };

  const logoData = {
    frontend: [
      'https://img.icons8.com/?size=100&id=Nlsua06Gvxel&format=png', 
      'https://img.icons8.com/?size=100&id=b5Z5U3tVLQu5&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=20909&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=21278&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=108784&format=png&color=000000',
      
    ],
    framework: [
      'https://img.icons8.com/?size=100&id=2ZOaTclOqD4q&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=hsPbhkOH4FMe&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=bzf0DqjXFHIW&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=71257&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=tbleCw0ch6QC&format=png&color=000000',
    ],
    platform: [
      'https://img.icons8.com/?size=100&id=17842&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=gXoJoyTtYXFg&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=uoRwwh0lz3Jp&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=VoXRGxL3ekkk&format=png&color=000000',
      'https://img.icons8.com/?size=100&id=cdYUlRaag9G9&format=png&color=000000',
    ],
  };

  // Service5 Section
  const serviceContainerStyle = {
    textAlign: 'center',
    padding: '50px 20px',
    fontFamily: "'Roboto', sans-serif",
    backgroundColor: '#000',
    color: '#fff',
    minHeight: '70vh',
  };

  const serviceHeadingStyle = {
    fontSize: '2.5em',
    fontWeight: '700',
    marginBottom: '20px',
  };

  const highlightStyle = {
    color: '#f0b90b',
  };

  const paragraphStyle = {
    fontSize: '1.2em',
    margin: '20px 0',
    maxWidth: '700px',
    marginLeft: 'auto',
    marginRight: 'auto',
  };

  const reasonsStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px',
    marginTop: '40px',
  };

  const reasonStyle = {
    backgroundColor: '#1a1a1a',
    padding: '20px',
    width: '250px',
    borderRadius: '8px',
    overflow: 'hidden',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.6)',
    transition: 'transform 0.3s ease, boxShadow 0.3s ease',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const reasonTitleStyle = {
    fontSize: '1.2em',
    fontWeight: '700',
    marginBottom: '10px',
    color: '#f0b90b',
  };

  const reasonTextStyle = {
    fontSize: '0.9em',
    color: '#ccc',
    position: 'absolute',
    bottom: '-100%',
    padding: '10px',
    backgroundColor: '#1a1a1a',
    width: '100%',
    textAlign: 'center',
    transition: 'bottom 0.3s ease',
  };

  const handleHover = (e, isHovering) => {
    e.currentTarget.style.transform = isHovering ? 'translateY(-10px)' : 'translateY(0)';
    const description = e.currentTarget.querySelector('p');
    description.style.bottom = isHovering ? '0' : '-100%';
  };

  return (
    <div>
      {/* Technologies Section */}
      <div style={technologiesContainerStyle}>
        <div style={titleStyle}>TECHNOLOGIES</div>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
          <div style={tabStyle(activeTab === 'frontend')} onClick={() => showContent('frontend')}>
            Frontend
          </div>
          <div style={tabStyle(activeTab === 'framework')} onClick={() => showContent('framework')}>
            Framework
          </div>
          <div style={tabStyle(activeTab === 'platform')} onClick={() => showContent('platform')}>
            Platform
          </div>
        </div>
        <div style={logoBoxStyle}>
          {logoData[activeTab].map((logoUrl, index) => (
            <div
              key={index}
              style={itemStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src={logoUrl}
                alt={`Logo ${index + 1}`}
                style={{ width: '80px', height: '80px', marginBottom: '10px' }}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Service5 Section */}
      <div style={serviceContainerStyle}>
        <h1 style={serviceHeadingStyle}>
          Why Choose <span style={highlightStyle}>KotiBox</span> for Digital Marketing?
        </h1>
        <p style={paragraphStyle}>
        As a full-service digital marketing agency, KotiBox excels at boosting your brand’s online visibility. By combining data-driven strategies with cutting-edge digital marketing tools, we help your brand connect with the right audience. Here’s why our digital marketing services make a difference:
        </p>
        <div style={reasonsStyle}>
          {["Targeted Campaigns", "Multi-Channel Strategies", "Data-Driven Insights", "Ongoing Optimization"].map((title, index) => (
            <div
              key={index}
              style={reasonStyle}
              onMouseEnter={(e) => handleHover(e, true)}
              onMouseLeave={(e) => handleHover(e, false)}
            >
              <h3 style={reasonTitleStyle}>Premium {title}</h3>
              <p style={reasonTextStyle}>
                {title === "Targeted Campaigns" && "We create focused campaigns tailored to reach your audience."}
                {title === "Multi-Channel Strategies" && "We enhance your presence across SEO, social media, and PPC."}
                {title === "Data-Driven Insights" && "Our strategies are built on data to maximize ROI."}
                {title === "Ongoing Optimization" && "We continuously refine campaigns for optimal performance."}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PageThreeDigital;
