import React from 'react';

const PageFiveStack = () => {
  const styles = {
    body: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: '#121212', // Dark mode background
      color: '#ffffff',
      padding: 0,
      margin: 0,
    },
    container: {
      padding: '50px 15px',
    },
    headerSpan: {
      color: '#f97316',
      fontSize: '14px',
      fontWeight: 700,
      textAlign: 'center',
      display: 'block',
      marginBottom: '10px',
    },
    headerH1: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: 1.3,
      textAlign: 'center',
      marginBottom: '30px',
    },
    serviceCard: {
      backgroundColor: '#1e1e1e', // Dark card background
      borderRadius: '12px',
      padding: '20px',
      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      margin: '0 10%', // 10% margin on left and right
      maxWidth: '80%', // Restrict card size
    },
    serviceCardHover: {
      transform: 'translateY(-10px)',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
    },
    icon: {
      fontSize: '40px',
      color: '#f97316',
    },
    serviceCardH3: {
      fontSize: '20px',
      fontWeight: 700,
      margin: '20px 0 10px',
      textAlign: 'center',
      color: '#ffffff',
    },
    serviceCardP: {
      fontSize: '14px',
      lineHeight: 1.6,
      color: '#b0b0b0',
      textAlign: 'justify',
    },
    highlightIcon: {
      fontSize: '14px',
      color: '#f97316',
      marginRight: '10px',
    },
    highlightSpan: {
      backgroundColor: '#f97316',
      color: '#ffffff',
      padding: '5px 10px',
      borderRadius: '20px',
      fontSize: '12px',
    },
    arrowIcon: {
      fontSize: '20px',
      color: '#f97316',
    },
  };

  const serviceData = [
    {
        icon: 'fas fa-code',
        title: 'Custom Full Stack Development',
        description:
          'Leverage the power of both front-end and back-end technologies with our custom full stack development services. We create dynamic, scalable, and secure web applications, ensuring seamless interaction between the client-side and server-side.',
        highlights: [
          'End-to-End Web Solutions',
          'Scalable and Secure Applications',
          'Seamless Front-End and Back-End Integration',
          'Optimized Performance',
        ],
      },
      {
        icon: 'fas fa-laptop-code',
        title: 'Front-End Development',
        description:
          'Build user-friendly, responsive, and visually appealing front-end interfaces. We use the latest frameworks and technologies like React.js, Angular, and Vue.js to ensure smooth navigation and enhanced user experiences.',
        highlights: [
          'Responsive Design',
          'Interactive User Interfaces',
          'Cross-Browser Compatibility',
          'Enhanced User Experience (UX)',
        ],
      },
      {
        icon: 'fas fa-server',
        title: 'Back-End Development',
        description:
          'Develop robust, scalable, and high-performance back-end systems with secure APIs, databases, and server-side applications. We use technologies such as Node.js, Python, and Ruby on Rails to create powerful back-end solutions.',
        highlights: [
          'Database Management and Integration',
          'RESTful API Development',
          'Server-Side Scripting',
          'Secure Authentication and Authorization',
        ],
      },
      {
        icon: 'fas fa-cloud',
        title: 'Cloud-Based Full Stack Solutions',
        description:
          'Take your full stack application to the cloud with our cloud-based solutions. We utilize cloud platforms like AWS, Google Cloud, and Azure to provide scalable, flexible, and high-availability solutions that grow with your business.',
        highlights: [
          'Cloud Infrastructure Setup',
          'Scalable and Flexible Solutions',
          'High Availability and Performance',
          'Seamless Cloud Integration',
        ],
      },
      {
        icon: 'fas fa-database',
        title: 'Database Development and Management',
        description:
          'Optimize your data storage and retrieval processes with our database development services. We design and implement efficient databases, using SQL, NoSQL, and cloud database technologies to ensure fast access and reliability.',
        highlights: [
          'Database Design and Optimization',
          'SQL/NoSQL Database Solutions',
          'Data Integrity and Security',
          'Real-Time Data Processing',
        ],
      },
      {
        icon: 'fas fa-code-branch',
        title: 'Version Control and Collaboration',
        description:
          'Enhance team collaboration with efficient version control systems. We use Git and other version control tools to ensure smooth code management, collaboration, and continuous delivery in your development process.',
        highlights: [
          'Git Version Control',
          'Collaborative Development',
          'Continuous Integration/Continuous Deployment (CI/CD)',
          'Efficient Code Management',
        ],
      },
      {
        icon: 'fas fa-shield-alt',
        title: 'Security-First Development',
        description:
          'Ensure the security of your full stack application with our security-first development practices. We implement robust security measures, such as encryption, multi-factor authentication, and secure APIs, to protect your application from vulnerabilities.',
        highlights: [
          'Secure API Development',
          'Encryption and Data Protection',
          'Multi-Factor Authentication',
          'Vulnerability Testing and Mitigation',
        ],
      },
      {
        icon: 'fas fa-tasks',
        title: 'Agile Development Process',
        description:
          'Leverage the power of Agile development for iterative and incremental delivery of your full stack application. Our team follows Agile methodologies to ensure faster delivery and continuous improvement throughout the development cycle.',
        highlights: [
          'Agile Methodologies',
          'Iterative and Incremental Development',
          'Frequent Product Updates',
          'Flexible Project Management',
        ],
      },
      {
        icon: 'fas fa-clipboard-list',
        title: 'Testing and Quality Assurance',
        description:
          'Ensure the reliability and performance of your full stack application with our comprehensive testing and quality assurance services. We conduct manual and automated testing to identify bugs, improve functionality, and enhance user experience.',
        highlights: [
          'Manual and Automated Testing',
          'Performance Testing',
          'Bug Identification and Fixes',
          'Quality Assurance for Better User Experience',
        ],
      },
      {
        icon: 'fas fa-mobile-alt',
        title: 'Mobile App Development Integration',
        description:
          'Integrate your full stack solution with mobile applications to reach a wider audience. We provide mobile app development services, ensuring that your application runs seamlessly across devices, from web to mobile.',
        highlights: [
          'Cross-Platform Mobile Development',
          'Mobile-Optimized Interfaces',
          'Smooth Integration with Full Stack Solutions',
          'Seamless User Experience Across Platforms',
        ],
      }
      
  ];

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div>
          <span style={styles.headerSpan}>SERVICES</span>
          <h1 style={styles.headerH1}>
            Our Expertise In FullStack Development
          </h1>
        </div>
        <div className="row g-4">
          {serviceData.map((service, index) => (
            <div className="col-12 col-md-6 col-lg-4" key={index}>
              <div
                style={styles.serviceCard}
                onMouseOver={(e) =>
                  Object.assign(e.currentTarget.style, styles.serviceCardHover)
                }
                onMouseOut={(e) =>
                  Object.assign(e.currentTarget.style, {
                    transform: 'translateY(0)',
                    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                  })
                }
              >
                <div className="text-center mb-3" style={styles.icon}>
                  <i className={service.icon}></i>
                </div>
                <h3 style={styles.serviceCardH3}>{service.title}</h3>
                <p style={styles.serviceCardP}>{service.description}</p>
                <div>
                  {service.highlights.map((highlight, idx) => (
                    <div key={idx} className="d-flex align-items-center mb-2">
                      <i
                        className="fas fa-check"
                        style={styles.highlightIcon}
                      ></i>
                      <span style={styles.highlightSpan}>{highlight}</span>
                    </div>
                  ))}
                </div>
                <div className="text-end">
                  <i className="fas fa-arrow-right" style={styles.arrowIcon}></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PageFiveStack;
