import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faChalkboardTeacher, faBook, faMobileAlt, faVials, faChartLine, faUsers, faVrCardboard } from '@fortawesome/free-solid-svg-icons';

const ThirdGame = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const styles = {
    body: {
      background: '#121212',
      color: '#ffffff',
      fontFamily: "'Roboto', sans-serif",
      margin: 0,
      padding: 0,
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      padding: '20px',
    },
    container: {
      width: '100%',
      maxWidth: '1200px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      gap: '30px',
    },
    heading: {
      fontSize: '42px',
      color: '#f57c00',
      textAlign: 'center',
      marginBottom: '20px',
    },
    paragraph: {
      fontSize: '18px',
      color: '#b3b3b3',
      maxWidth: '800px',
      textAlign: 'center',
      margin: '0 auto 50px',
    },
    servicesContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
      justifyContent: 'center',
    },
    serviceItem: {
      backgroundColor: '#1C1C1C',
      borderRadius: '15px',
      padding: '30px',
      width: '100%',
      maxWidth: '300px',
      textAlign: 'center',
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)', // Enhanced shadow for depth
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      cursor: 'pointer',
    },
    serviceItemHovered: {
      transform: 'scale(1.05)',
      boxShadow: '0 12px 24px rgba(0, 0, 0, 0.7)', // Stronger shadow on hover
    },
    icon: {
      fontSize: '50px',
      color: '#f57c00',
      marginBottom: '20px',
    },
    serviceTitle: {
      fontSize: '24px',
      color: '#f57c00',
      marginBottom: '10px',
      fontWeight: 'bold', // Bold title for prominence
    },
    serviceDescription: {
      fontSize: '16px',
      color: '#ccc',
      maxHeight: '0',
      overflow: 'hidden',
      transition: 'max-height 0.3s ease',
    },
    serviceDescriptionVisible: {
      maxHeight: '150px',
    },
  };

  const services = [
    { icon: faGraduationCap, title: 'Cloud Storage & Syncing', description: '   Save game progress and player data to the cloud, allowing users to pick up where they left off on any device.' },
    { icon: faChalkboardTeacher, title: 'Game Monetization Models', description: 'Multiple monetization options, including in-app purchases, subscriptions, and ads.' },
    { icon: faBook, title: 'Leaderboards & Achievements', description: '   Built-in social features like leaderboards, achievements, and social sharing to increase engagement.' },
    { icon: faMobileAlt, title: 'Augmented Reality (AR) Features', description: 'Incorporate AR elements to make games more immersive and interactive.' },
    { icon: faVials, title: 'Secure Payment Systems', description: 'Robust and secure payment gateways for transactions in-game, preventing fraud.' },
    { icon: faChartLine, title: 'Game Testing & Quality Assurance', description: '  Comprehensive testing to ensure smooth gameplay, bug-free experiences, and high-quality visuals.' },
    { icon: faUsers, title: 'Real-Time Communication', description: 'Voice and text chat features for seamless communication between players.' },
    { icon: faVrCardboard, title: 'Adaptive Difficulty Levels', description: 'AI-driven difficulty adjustments to keep the game challenging and engaging for players of all levels.' },
  ];

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <h1 style={styles.heading}>Our Game Development Solutions</h1>
        <p style={styles.paragraph}>
        Kotibox builds authentic and user-centric Gaming Apps & Websites designed for growth. Our goal is to create platforms that effectively convey your content in a well-formulated flow.
        </p>
        <div style={styles.servicesContainer}>
          {services.map((service, index) => (
            <div
              key={index}
              style={{
                ...styles.serviceItem,
                ...(hoveredIndex === index ? styles.serviceItemHovered : {}),
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <FontAwesomeIcon icon={service.icon} style={styles.icon} />
              <h3 style={styles.serviceTitle}>{service.title}</h3>
              <p style={{
                ...styles.serviceDescription,
                ...(hoveredIndex === index ? styles.serviceDescriptionVisible : {}),
              }}>
                {service.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ThirdGame;
