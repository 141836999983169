import React from 'react';

const PageFiveCRM = () => {
  const styles = {
    body: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: '#121212', // Dark mode background
      color: '#ffffff',
      padding: 0,
      margin: 0,
    },
    container: {
      padding: '50px 15px',
    },
    headerSpan: {
      color: '#f97316',
      fontSize: '14px',
      fontWeight: 700,
      textAlign: 'center',
      display: 'block',
      marginBottom: '10px',
    },
    headerH1: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: 1.3,
      textAlign: 'center',
      marginBottom: '30px',
    },
    serviceCard: {
      backgroundColor: '#1e1e1e', // Dark card background
      borderRadius: '12px',
      padding: '20px',
      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      margin: '0 10%', // 10% margin on left and right
      maxWidth: '80%', // Restrict card size
    },
    serviceCardHover: {
      transform: 'translateY(-10px)',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
    },
    icon: {
      fontSize: '40px',
      color: '#f97316',
    },
    serviceCardH3: {
      fontSize: '20px',
      fontWeight: 700,
      margin: '20px 0 10px',
      textAlign: 'center',
      color: '#ffffff',
    },
    serviceCardP: {
      fontSize: '14px',
      lineHeight: 1.6,
      color: '#b0b0b0',
      textAlign: 'justify',
    },
    highlightIcon: {
      fontSize: '14px',
      color: '#f97316',
      marginRight: '10px',
    },
    highlightSpan: {
      backgroundColor: '#f97316',
      color: '#ffffff',
      padding: '5px 10px',
      borderRadius: '20px',
      fontSize: '12px',
    },
    arrowIcon: {
      fontSize: '20px',
      color: '#f97316',
    },
  };

  const serviceData = [
    {
        icon: 'fas fa-users-cog',
        title: 'Custom CRM Development',
        description:
          'Develop a tailored Customer Relationship Management (CRM) system to meet the unique needs of your business. We create CRM solutions that streamline sales, marketing, and customer service, enhancing productivity and improving customer satisfaction.',
        highlights: [
          'Tailored CRM Solutions',
          'Streamlined Sales and Marketing',
          'Enhanced Customer Interaction',
          'Improved Data Management',
        ],
      },
      {
        icon: 'fas fa-chart-bar',
        title: 'CRM Analytics and Reporting',
        description:
          'Gain actionable insights into your customer relationships with advanced analytics and reporting tools integrated into your CRM. We help you track performance, analyze trends, and make data-driven decisions to improve business outcomes.',
        highlights: [
          'Advanced Analytics Tools',
          'Customizable Dashboards',
          'Real-Time Reporting',
          'Data-Driven Decision Making',
        ],
      },
      {
        icon: 'fas fa-sync-alt',
        title: 'CRM Integration Services',
        description:
          'Seamlessly integrate your CRM with existing systems and third-party applications to centralize customer data, streamline workflows, and improve operational efficiency. We ensure your CRM works flawlessly with your current tools.',
        highlights: [
          'Seamless System Integration',
          'Improved Workflow Efficiency',
          'Centralized Data Management',
          'Enhanced Collaboration Across Teams',
        ],
      },
      {
        icon: 'fas fa-robot',
        title: 'AI-Powered CRM Automation',
        description:
          'Leverage the power of AI to automate routine tasks within your CRM. From lead scoring to automated follow-ups, our AI-driven solutions help you reduce manual work, improve customer engagement, and boost productivity.',
        highlights: [
          'Automated Lead Scoring',
          'AI-Powered Follow-Ups',
          'Streamlined Sales Processes',
          'Increased Efficiency and Productivity',
        ],
      },
      {
        icon: 'fas fa-cloud',
        title: 'Cloud-Based CRM Solutions',
        description:
          'Move your CRM to the cloud for greater accessibility, scalability, and flexibility. Our cloud-based CRM solutions ensure that your team can access customer data securely from anywhere, anytime, with real-time updates.',
        highlights: [
          'Scalable Cloud Solutions',
          'Real-Time Data Access',
          'Mobile and Remote Access',
          'Secure Cloud-Based Storage',
        ],
      },
      {
        icon: 'fas fa-lock',
        title: 'CRM Security and Compliance',
        description:
          'Protect your customer data with robust security features built into your CRM. We implement encryption, data access controls, and ensure your CRM complies with industry regulations to safeguard your business and customers.',
        highlights: [
          'Data Encryption and Security',
          'Regulatory Compliance (GDPR, CCPA)',
          'Access Control and User Permissions',
          'Advanced Fraud Prevention',
        ],
      },
      {
        icon: 'fas fa-cogs',
        title: 'CRM Customization and Optimization',
        description:
          'Optimize and customize your CRM to match your business processes. We tailor features, workflows, and interfaces to ensure that your CRM is perfectly aligned with your sales, marketing, and customer service strategies.',
        highlights: [
          'Custom Features and Workflows',
          'Optimized User Interfaces',
          'Improved Sales and Service Processes',
          'Increased CRM Adoption Across Teams',
        ],
      },
      {
        icon: 'fas fa-comments',
        title: 'Customer Support and Service CRM',
        description:
          'Enhance your customer support with a CRM system designed to improve ticketing, case management, and customer interactions. Our solutions help your support team deliver personalized, efficient service that boosts customer satisfaction and loyalty.',
        highlights: [
          'Efficient Case Management',
          'Automated Ticketing System',
          'Real-Time Support Dashboards',
          'Personalized Customer Service',
        ],
      },
      {
        icon: 'fas fa-users',
        title: 'Sales and Marketing CRM',
        description:
          'Boost your sales and marketing efforts with a CRM system that helps you track leads, manage customer relationships, and optimize your sales pipeline. We provide tools to increase conversions, drive revenue, and improve customer engagement.',
        highlights: [
          'Lead and Opportunity Management',
          'Targeted Marketing Campaigns',
          'Sales Pipeline Optimization',
          'Customer Engagement and Retention',
        ],
      }
      
  ];

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div>
          <span style={styles.headerSpan}>SERVICES</span>
          <h1 style={styles.headerH1}>
            Our Expertise In CRM Development
          </h1>
        </div>
        <div className="row g-4">
          {serviceData.map((service, index) => (
            <div className="col-12 col-md-6 col-lg-4" key={index}>
              <div
                style={styles.serviceCard}
                onMouseOver={(e) =>
                  Object.assign(e.currentTarget.style, styles.serviceCardHover)
                }
                onMouseOut={(e) =>
                  Object.assign(e.currentTarget.style, {
                    transform: 'translateY(0)',
                    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                  })
                }
              >
                <div className="text-center mb-3" style={styles.icon}>
                  <i className={service.icon}></i>
                </div>
                <h3 style={styles.serviceCardH3}>{service.title}</h3>
                <p style={styles.serviceCardP}>{service.description}</p>
                <div>
                  {service.highlights.map((highlight, idx) => (
                    <div key={idx} className="d-flex align-items-center mb-2">
                      <i
                        className="fas fa-check"
                        style={styles.highlightIcon}
                      ></i>
                      <span style={styles.highlightSpan}>{highlight}</span>
                    </div>
                  ))}
                </div>
                <div className="text-end">
                  <i className="fas fa-arrow-right" style={styles.arrowIcon}></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PageFiveCRM;
