import React from 'react';

function Devfour() {
  return (
    <div style={styles.container}>
      <h2 style={styles.title}>
        Why Hire <span style={styles.highlight}>JavaScript Developers</span> From{' '}
        <span style={styles.company}>Kotibox</span>?
      </h2>
      <p style={styles.description}>
      
      At Kotibox, we deliver sophisticated, high-impact solutions tailored to your business goals. Our developers specialize in creating scalable, innovative applications that exceed expectations and align seamlessly with your strategic vision for long-term success.
      </p>
      <div style={styles.grid} className="grid">
        {[
          'Experienced Developers',
          'Full Project Transparency',
          'Optimized Budgeting and Scope',
          'Timely Project Execution',
          'Confidentiality and IP Protection',
          'Regular Progress Reports',
          'No Long-Term Commitments',
          'Customizable Engagement Plans',
          'Seamless Scalability',
          'Round-the-Clock Assistance',
          'Agile Development Methodology',
          'Tailored Solutions for Growth',
        ].map((text, index) => (
          <div
            key={index}
            style={styles.item}
            className="shine"
            aria-label={text}
          >
            <span style={styles.icon}>✔</span> {text}
            <div style={styles.shineEffect}></div>
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: '#1c1c1c',
    color: '#fff',
    padding: '40px 20px',
    maxWidth: '100%',
    margin: 'auto',
  },
  title: {
    fontSize: '28px',
    fontWeight: '700',
    textAlign: 'center',
    color: '#ffffff',
    marginBottom: '20px',
  },
  highlight: {
    color: '#e6a919',
  },
  company: {
    color: '#e6a919',
  },
  description: {
    fontSize: '16px',
    color: '#b0b0b0',
    textAlign: 'center',
    marginBottom: '30px',
  },
  grid: {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', // Auto-resizing grid
  },
  item: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: '500',
    padding: '15px',
    borderRadius: '6px',
    backgroundColor: '#292929',
    color: '#ffffff',
    transition: 'transform 0.3s, box-shadow 0.3s',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
    overflow: 'hidden',
  },
  icon: {
    color: '#00c2c2',
    marginRight: '10px',
    fontSize: '20px',
  },
  shineEffect: {
    position: 'absolute',
    top: 0,
    left: '-75%',
    width: '150%',
    height: '100%',
    background: 'linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.4), transparent)',
    transform: 'rotate(30deg)',
    transition: 'transform 0.6s',
    pointerEvents: 'none',
  },
};

// Add CSS styles for media queries and the shine animation
const additionalStyles = document.createElement('style');
additionalStyles.innerHTML = `
  .shine:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(255, 255, 255, 0.4);
  }

  .shine:hover div {
    animation: shineAnimation 0.6s forwards;
  }

  @keyframes shineAnimation {
    from {
      transform: translateX(-100%) rotate(30deg);
    }
    to {
      transform: translateX(100%) rotate(30deg);
    }
  }

  /* Responsive grid layout */
  @media (max-width: 1024px) {
    .grid {
      gap: 15px; /* Adjust spacing for medium screens */
    }
  }

  @media (max-width: 768px) {
    .grid {
      gap: 10px; /* Adjust spacing for small screens */
    }
  }
`;
document.head.appendChild(additionalStyles);

export default Devfour;
