import React from 'react';

const PagefiveContent = () => {
  const styles = {
    body: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: '#121212', // Dark mode background
      color: '#ffffff',
      padding: 0,
      margin: 0,
    },
    container: {
      padding: '50px 15px',
    },
    headerSpan: {
      color: '#f97316',
      fontSize: '14px',
      fontWeight: 700,
      textAlign: 'center',
      display: 'block',
      marginBottom: '10px',
    },
    headerH1: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: 1.3,
      textAlign: 'center',
      marginBottom: '30px',
    },
    serviceCard: {
      backgroundColor: '#1e1e1e', // Dark card background
      borderRadius: '12px',
      padding: '20px',
      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      margin: '0 10%', // 10% margin on left and right
      maxWidth: '80%', // Restrict card size
    },
    serviceCardHover: {
      transform: 'translateY(-10px)',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
    },
    icon: {
      fontSize: '40px',
      color: '#f97316',
    },
    serviceCardH3: {
      fontSize: '20px',
      fontWeight: 700,
      margin: '20px 0 10px',
      textAlign: 'center',
      color: '#ffffff',
    },
    serviceCardP: {
      fontSize: '14px',
      lineHeight: 1.6,
      color: '#b0b0b0',
      textAlign: 'justify',
    },
    highlightIcon: {
      fontSize: '14px',
      color: '#f97316',
      marginRight: '10px',
    },
    highlightSpan: {
      backgroundColor: '#f97316',
      color: '#ffffff',
      padding: '5px 10px',
      borderRadius: '20px',
      fontSize: '12px',
    },
    arrowIcon: {
      fontSize: '20px',
      color: '#f97316',
    },
  };

  const serviceData = [
    {
        icon: 'fas fa-pencil-alt',
        title: 'Content Strategy Development',
        description:
          'Develop a winning content strategy that aligns with your brand goals and engages your target audience. Our team creates data-driven, personalized strategies to increase visibility, drive traffic, and boost conversions.',
        highlights: [
          'Tailored Content Plans',
          'Audience Research and Targeting',
          'SEO-Optimized Content',
          'Effective Brand Storytelling',
        ],
      },
      {
        icon: 'fas fa-pen',
        title: 'Blog and Article Writing',
        description:
          'Engage your audience with well-crafted blog posts and articles. Our expert writers create informative, engaging, and SEO-friendly content that not only attracts readers but also positions your brand as an authority in your industry.',
        highlights: [
          'SEO-Friendly Blog Content',
          'Engaging and Informative Articles',
          'Industry-Specific Expertise',
          'Consistent Brand Voice',
        ],
      },
      {
        icon: 'fas fa-share-alt',
        title: 'Social Media Content Creation',
        description:
          'Boost your brand’s social media presence with compelling content. We create shareable and engaging social media posts that foster community interaction, increase followers, and drive brand awareness.',
        highlights: [
          'Engaging Social Media Posts',
          'Targeted Social Campaigns',
          'Brand Awareness and Growth',
          'Social Media Content Calendars',
        ],
      },
      {
        icon: 'fas fa-search',
        title: 'SEO Content Optimization',
        description:
          'Increase your search engine rankings with expertly optimized content. We help you create SEO-friendly articles, blogs, and web pages that attract organic traffic and improve your visibility on search engines like Google.',
        highlights: [
          'SEO-Optimized Content',
          'Keyword Research and Integration',
          'Improved Organic Traffic',
          'Higher Search Engine Rankings',
        ],
      },
      {
        icon: 'fas fa-video',
        title: 'Video Content Production',
        description:
          'Leverage the power of video content to engage and educate your audience. We produce high-quality videos that communicate your message, tell your brand story, and enhance customer interaction across platforms.',
        highlights: [
          'Engaging Video Marketing',
          'Brand Storytelling Through Video',
          'Platform-Specific Video Content',
          'Enhanced Audience Engagement',
        ],
      },
      {
        icon: 'fas fa-image',
        title: 'Visual Content Creation',
        description:
          'Create stunning visual content that captures attention and drives engagement. From infographics to custom images, we design eye-catching visuals that complement your written content and enhance your messaging.',
        highlights: [
          'Custom Infographics and Visuals',
          'Eye-Catching Designs',
          'Enhanced Content Engagement',
          'Visual Storytelling',
        ],
      },
      {
        icon: 'fas fa-calendar-check',
        title: 'Content Calendar Management',
        description:
          'Stay organized and consistent with a well-planned content calendar. We help you schedule and manage content publishing across multiple channels, ensuring timely delivery and maximizing audience engagement.',
        highlights: [
          'Content Scheduling and Planning',
          'Timely Content Delivery',
          'Cross-Platform Content Management',
          'Increased Audience Engagement',
        ],
      },
      {
        icon: 'fas fa-cogs',
        title: 'Content Repurposing',
        description:
          'Maximize the value of your existing content by repurposing it across different formats and channels. We transform your blogs, articles, and videos into podcasts, eBooks, social media posts, and more to reach a wider audience.',
        highlights: [
          'Repurpose Existing Content',
          'Multiple Content Formats',
          'Extended Content Lifespan',
          'Wider Audience Reach',
        ],
      },
      {
        icon: 'fas fa-envelope',
        title: 'Email Marketing Campaigns',
        description:
          'Increase engagement and conversions with targeted email campaigns. We craft personalized email content that resonates with your audience, nurtures leads, and drives measurable results.',
        highlights: [
          'Personalized Email Content',
          'Targeted Campaigns and Newsletters',
          'Lead Nurturing and Conversion',
          'Data-Driven Email Performance',
        ],
      }
      
      
    
  ];

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div>
          <span style={styles.headerSpan}>SERVICES</span>
          <h1 style={styles.headerH1}>
            Our Expertise In Content Marketing
          </h1>
        </div>
        <div className="row g-4">
          {serviceData.map((service, index) => (
            <div className="col-12 col-md-6 col-lg-4" key={index}>
              <div
                style={styles.serviceCard}
                onMouseOver={(e) =>
                  Object.assign(e.currentTarget.style, styles.serviceCardHover)
                }
                onMouseOut={(e) =>
                  Object.assign(e.currentTarget.style, {
                    transform: 'translateY(0)',
                    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                  })
                }
              >
                <div className="text-center mb-3" style={styles.icon}>
                  <i className={service.icon}></i>
                </div>
                <h3 style={styles.serviceCardH3}>{service.title}</h3>
                <p style={styles.serviceCardP}>{service.description}</p>
                <div>
                  {service.highlights.map((highlight, idx) => (
                    <div key={idx} className="d-flex align-items-center mb-2">
                      <i
                        className="fas fa-check"
                        style={styles.highlightIcon}
                      ></i>
                      <span style={styles.highlightSpan}>{highlight}</span>
                    </div>
                  ))}
                </div>
                <div className="text-end">
                  <i className="fas fa-arrow-right" style={styles.arrowIcon}></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PagefiveContent;
