import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faChalkboardTeacher, faBook, faMobileAlt, faVials, faChartLine, faUsers, faVrCardboard } from '@fortawesome/free-solid-svg-icons';

const App = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const styles = {
    body: {
      background: '#121212',
      color: '#ffffff',
      fontFamily: "'Roboto', sans-serif",
      margin: 0,
      padding: 0,
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      padding: '20px',
    },
    container: {
      width: '100%',
      maxWidth: '1200px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      gap: '30px',
    },
    heading: {
      fontSize: '42px',
      color: '#f57c00',
      textAlign: 'center',
      marginBottom: '20px',
    },
    paragraph: {
      fontSize: '18px',
      color: '#b3b3b3',
      maxWidth: '800px',
      textAlign: 'center',
      margin: '0 auto 50px',
    },
    servicesContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
      justifyContent: 'center',
    },
    serviceItem: {
      backgroundColor: '#1C1C1C',
      borderRadius: '15px',
      padding: '30px',
      width: '100%',
      maxWidth: '300px',
      textAlign: 'center',
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)', // Enhanced shadow for depth
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      cursor: 'pointer',
    },
    serviceItemHovered: {
      transform: 'scale(1.05)',
      boxShadow: '0 12px 24px rgba(0, 0, 0, 0.7)', // Stronger shadow on hover
    },
    icon: {
      fontSize: '50px',
      color: '#f57c00',
      marginBottom: '20px',
    },
    serviceTitle: {
      fontSize: '24px',
      color: '#f57c00',
      marginBottom: '10px',
      fontWeight: 'bold', // Bold title for prominence
    },
    serviceDescription: {
      fontSize: '16px',
      color: '#ccc',
      maxHeight: '0',
      overflow: 'hidden',
      transition: 'max-height 0.3s ease',
    },
    serviceDescriptionVisible: {
      maxHeight: '150px',
    },
  };

  const services = [
    { icon: faGraduationCap, title: 'Delivery Management', description: 'Manage in-house or third-party delivery services, optimizing delivery times and routes for efficiency.' },
    { icon: faChalkboardTeacher, title: 'Customer Feedback & Reviews', description: 'Integrated review systems that help you understand customer sentiment and improve services.' },
    { icon: faBook, title: 'Multi-Location Support', description: 'Manage multiple locations from a single platform, with customized offerings per location.' },
    { icon: faMobileAlt, title: 'Mobile App Development', description: 'Custom apps for restaurants that allow customers to place orders, book tables, and access promotions.' },
    { icon: faVials, title: 'Promotions & Discounts', description: ' Easy-to-set-up promotional offers, coupons, and discount codes to drive sales' },
    { icon: faChartLine, title: 'Analytics & Reporting', description: 'Real-time analytics on sales, customer behavior, and inventory to guide decision-making.' },
    { icon: faUsers, title: 'Employee Scheduling', description: 'Tools to manage employee shifts and availability, ensuring smooth operations.' },
    { icon: faVrCardboard, title: 'Social Media Integration', description: 'Integrate social media platforms to engage customers, promote specials, and gather feedback.' },
  ];

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <h1 style={styles.heading}>Our Food & Restaurants Solutions</h1>
        <p style={styles.paragraph}>
        Kotibox builds authentic and user-centric Food & Restaurants Apps & Websites designed for growth. Our goal is to create platforms that effectively convey your content in a well-formulated flow.
        </p>
        <div style={styles.servicesContainer}>
          {services.map((service, index) => (
            <div
              key={index}
              style={{
                ...styles.serviceItem,
                ...(hoveredIndex === index ? styles.serviceItemHovered : {}),
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <FontAwesomeIcon icon={service.icon} style={styles.icon} />
              <h3 style={styles.serviceTitle}>{service.title}</h3>
              <p style={{
                ...styles.serviceDescription,
                ...(hoveredIndex === index ? styles.serviceDescriptionVisible : {}),
              }}>
                {service.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;
