import React, { useState, useRef } from "react";
import kotiboxabout from "../imgvdo/kotiboxabout.mov";
import imgvdo from "../imgvdo/imgvdo.gif";
import CountUp from "react-countup"; // Import the CountUp component

const About = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const handleVideoClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const toggleMute = () => {
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  return (
    <>
    
    <div style={styles.container}>
      {/* Left Section */}
      <div style={styles.leftSection}>
        <h4 style={styles.smallHeading}>ABOUT US</h4>
        <h1 style={styles.mainHeading}>
          Built Relationships with 12,500+ Happy Clients!
        </h1>
        <p style={styles.paragraph}>
          Trusted by more than 12,500 satisfied clients, we consistently deliver
          proven results through timely execution and exceptional service.
        </p>

        {/* Statistics Row */}
        <div style={styles.statsContainer}>
          {[
            { number: "50", label: "Employee count" },
            { number: "96", label: "Client Satisfaction" },
            { number: "1100", label: "Happy Clients" },
            { number: "2000", label: "Projects" },
            { number: "8", label: "Years" },
            { number: "3", label: "Offices" },
          ].map((stat, index) => (
            <div style={styles.statBox} key={index}>
              <span style={styles.statNumber}>
                <CountUp start={0} end={parseInt(stat.number.replace(',', ''))} duration={2.5} />
              </span>
              <span style={styles.statText}>{stat.label}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Right Section */}
      <div style={styles.rightSection}>
        <h3 style={styles.magazineHeading}>KOTIBOX</h3>
        <h1 style={styles.magazineTitle}>Innovating Tomorrow, Shaping Your Future</h1>

        {/* Video Card */}
        <div style={styles.videoCard}>
          <div style={styles.videoContainer}>
            <video
              ref={videoRef}
              style={styles.video}
              width="100%"
              poster={imgvdo}
            >
              <source src={kotiboxabout} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          {/* Controls */}
          <div style={styles.controls}>
            <button style={styles.controlButton} onClick={handleVideoClick}>
              {isPlaying ? "Pause" : "Play"}
            </button>
            <button style={styles.controlButton} onClick={toggleMute}>
              {isMuted ? "Unmute" : "Mute"}
            </button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#000",
    color: "#fff",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  leftSection: {
    width: "100%",
    maxWidth: "800px",
    textAlign: "center",
    marginBottom: "20px",
  },
  smallHeading: {
    color: "#FF6B00",
    fontSize: "16px",
  },
  mainHeading: {
    fontSize: "24px",
    lineHeight: "1.2",
    margin: "10px 0",
  },
  paragraph: {
    fontSize: "16px",
    color: "#AAA",
    lineHeight: "1.6",
    marginBottom: "20px",
  },
  statsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    gap: "20px",
    marginTop: "30px",
  },
  statBox: {
    width: "calc(33.33% - 20px)",  // Ensures 3 items fit in a row with spacing
    textAlign: "center",
    marginBottom: "20px",
  },
  statNumber: {
    display: "block",
    fontSize: "28px",
    fontWeight: "bold",
    color: "#FF6B00",
  },
  statText: {
    fontSize: "14px",
    color: "#AAA",
  },
  rightSection: {
    width: "100%",
    maxWidth: "800px",
    textAlign: "center",
    marginTop: "40px",
  },
  magazineHeading: {
    color: "#FF6B00",
    fontSize: "14px",
    marginBottom: "10px",
  },
  magazineTitle: {
    fontSize: "18px",
    margin: "20px 0",
  },
  videoCard: {
    backgroundColor: "#1c1c1e",
    borderRadius: "15px",
    padding: "20px",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.3)",
  },
  videoContainer: {
    position: "relative",
    cursor: "pointer",
  },
  video: {
    borderRadius: "10px",
    maxWidth: "100%",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  controlButton: {
    backgroundColor: "#FF6B00",
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    fontSize: "14px",
    cursor: "pointer",
    borderRadius: "5px",
    margin: "0 5px",
  },
  
};

export default About;
