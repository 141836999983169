import React from 'react';

const PageFiveUI = () => {
  const styles = {
    body: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: '#121212', // Dark mode background
      color: '#ffffff',
      padding: 0,
      margin: 0,
    },
    container: {
      padding: '50px 15px',
    },
    headerSpan: {
      color: '#f97316',
      fontSize: '14px',
      fontWeight: 700,
      textAlign: 'center',
      display: 'block',
      marginBottom: '10px',
    },
    headerH1: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: 1.3,
      textAlign: 'center',
      marginBottom: '30px',
    },
    serviceCard: {
      backgroundColor: '#1e1e1e', // Dark card background
      borderRadius: '12px',
      padding: '20px',
      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      margin: '0 10%', // 10% margin on left and right
      maxWidth: '80%', // Restrict card size
    },
    serviceCardHover: {
      transform: 'translateY(-10px)',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
    },
    icon: {
      fontSize: '40px',
      color: '#f97316',
    },
    serviceCardH3: {
      fontSize: '20px',
      fontWeight: 700,
      margin: '20px 0 10px',
      textAlign: 'center',
      color: '#ffffff',
    },
    serviceCardP: {
      fontSize: '14px',
      lineHeight: 1.6,
      color: '#b0b0b0',
      textAlign: 'justify',
    },
    highlightIcon: {
      fontSize: '14px',
      color: '#f97316',
      marginRight: '10px',
    },
    highlightSpan: {
      backgroundColor: '#f97316',
      color: '#ffffff',
      padding: '5px 10px',
      borderRadius: '20px',
      fontSize: '12px',
    },
    arrowIcon: {
      fontSize: '20px',
      color: '#f97316',
    },
  };

  const serviceData = [
    {
        icon: 'fas fa-paint-brush',
        title: 'UI/UX Design',
        description:
          'Create intuitive and visually appealing user interfaces that prioritize the user experience. Our UI/UX design services focus on enhancing usability, interaction, and aesthetics to ensure seamless and enjoyable digital experiences.',
        highlights: [
          'User-Centered Design Approach',
          'Visually Appealing and Functional Interfaces',
          'Intuitive Navigation and Interaction',
          'Cross-Platform Design for Web and Mobile',
        ],
      },
      {
        icon: 'fas fa-tachometer-alt',
        title: 'UX Research and Testing',
        description:
          'Ensure your digital product meets user needs with in-depth UX research and usability testing. We gather insights through user interviews, surveys, and testing to refine your design for better engagement and conversion.',
        highlights: [
          'User Interviews and Surveys',
          'Usability Testing and Prototyping',
          'Data-Driven Design Decisions',
          'Enhanced User Engagement and Satisfaction',
        ],
      },
      {
        icon: 'fas fa-laptop-code',
        title: 'UI Development',
        description:
          'Transform designs into high-quality, interactive, and responsive user interfaces. Our front-end development team uses the latest web technologies like HTML5, CSS3, and JavaScript frameworks to bring your UI/UX vision to life.',
        highlights: [
          'Responsive and Mobile-Friendly UI',
          'Interactive and Dynamic Elements',
          'Cross-Browser Compatibility',
          'Optimized for Performance and Speed',
        ],
      },
      {
        icon: 'fas fa-video',
        title: 'Videography Services',
        description:
          'Capture stunning videos that tell your brand’s story and engage your audience. Our videography services include filming, editing, and post-production, ensuring high-quality, impactful videos for marketing, training, and branding purposes.',
        highlights: [
          'High-Quality Filming and Editing',
          'Creative Storytelling and Concept Development',
          'Corporate Videos, Ads, and Promotional Content',
          'Engaging Content for Social Media Platforms',
        ],
      },
      {
        icon: 'fas fa-clapperboard',
        title: 'Promotional and Explainer Videos',
        description:
          'Create compelling promotional and explainer videos that effectively communicate your message and drive conversions. We focus on clear, engaging visuals and storytelling that highlight your product or service’s value proposition.',
        highlights: [
          'Attention-Grabbing Visuals',
          'Concise and Clear Messaging',
          'Brand Storytelling',
          'Increased Conversion Rates',
        ],
      },
      {
        icon: 'fas fa-lightbulb',
        title: 'Concept Development and Storyboarding',
        description:
          'Bring your ideas to life with thorough concept development and storyboarding. We collaborate with you to create a visual roadmap that aligns with your brand and objectives, ensuring a clear direction for your video content.',
        highlights: [
          'Collaborative Concept Creation',
          'Detailed Storyboarding for Clear Direction',
          'Brand-Aligned Visual Aesthetic',
          'Creative and Strategic Video Planning',
        ],
      },
      {
        icon: 'fas fa-image',
        title: 'Cinematography and Filming',
        description:
          'Capture cinematic footage with our professional videography team. We utilize the latest filming techniques, equipment, and lighting to produce visually stunning and immersive video content tailored to your brand’s identity.',
        highlights: [
          'High-Quality Cinematic Footage',
          'State-of-the-Art Equipment and Lighting',
          'Creative Filming Techniques',
          'Visually Engaging Content for All Platforms',
        ],
      },
      {
        icon: 'fas fa-edit',
        title: 'Video Editing and Post-Production',
        description:
          'Enhance your video content with expert editing and post-production services. Our team ensures your video is polished, with seamless transitions, color grading, sound design, and special effects that elevate the final product.',
        highlights: [
          'Professional Video Editing and Enhancement',
          'Color Grading and Visual Effects',
          'Sound Design and Audio Optimization',
          'Final Product Ready for All Platforms',
        ],
      },
      {
        icon: 'fas fa-share-alt',
        title: 'Video Marketing and Distribution',
        description:
          'Maximize the impact of your video content with targeted video marketing and distribution strategies. We help you reach your audience on the right platforms, ensuring your video gains the attention it deserves.',
        highlights: [
          'Targeted Video Marketing Campaigns',
          'Social Media and Platform-Specific Optimization',
          'Increased Brand Visibility',
          'Engagement-Driven Content Distribution',
        ],
      },
      {
        icon: 'fas fa-laptop',
        title: 'User Interface and Video Integration',
        description:
          'Integrate videos seamlessly into your website or app’s user interface, enhancing the user experience. Our team designs video elements that complement your UI/UX design, ensuring smooth transitions and easy accessibility.',
        highlights: [
          'Seamless Video Embedding and Integration',
          'Enhanced User Interaction with Video',
          'Optimized for Speed and Performance',
          'Responsive Video Elements Across Devices',
        ],
      }
      
  ];

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div>
          <span style={styles.headerSpan}>SERVICES</span>
          <h1 style={styles.headerH1}>
            Our Expertise In UI/UX & Videography
          </h1>
        </div>
        <div className="row g-4">
          {serviceData.map((service, index) => (
            <div className="col-12 col-md-6 col-lg-4" key={index}>
              <div
                style={styles.serviceCard}
                onMouseOver={(e) =>
                  Object.assign(e.currentTarget.style, styles.serviceCardHover)
                }
                onMouseOut={(e) =>
                  Object.assign(e.currentTarget.style, {
                    transform: 'translateY(0)',
                    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                  })
                }
              >
                <div className="text-center mb-3" style={styles.icon}>
                  <i className={service.icon}></i>
                </div>
                <h3 style={styles.serviceCardH3}>{service.title}</h3>
                <p style={styles.serviceCardP}>{service.description}</p>
                <div>
                  {service.highlights.map((highlight, idx) => (
                    <div key={idx} className="d-flex align-items-center mb-2">
                      <i
                        className="fas fa-check"
                        style={styles.highlightIcon}
                      ></i>
                      <span style={styles.highlightSpan}>{highlight}</span>
                    </div>
                  ))}
                </div>
                <div className="text-end">
                  <i className="fas fa-arrow-right" style={styles.arrowIcon}></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PageFiveUI;
