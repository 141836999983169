import React from 'react';

const PageFiveSoftware = () => {
  const styles = {
    body: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: '#121212', // Dark mode background
      color: '#ffffff',
      padding: 0,
      margin: 0,
    },
    container: {
      padding: '50px 15px',
    },
    headerSpan: {
      color: '#f97316',
      fontSize: '14px',
      fontWeight: 700,
      textAlign: 'center',
      display: 'block',
      marginBottom: '10px',
    },
    headerH1: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: 1.3,
      textAlign: 'center',
      marginBottom: '30px',
    },
    serviceCard: {
      backgroundColor: '#1e1e1e', // Dark card background
      borderRadius: '12px',
      padding: '20px',
      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      margin: '0 10%', // 10% margin on left and right
      maxWidth: '80%', // Restrict card size
    },
    serviceCardHover: {
      transform: 'translateY(-10px)',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
    },
    icon: {
      fontSize: '40px',
      color: '#f97316',
    },
    serviceCardH3: {
      fontSize: '20px',
      fontWeight: 700,
      margin: '20px 0 10px',
      textAlign: 'center',
      color: '#ffffff',
    },
    serviceCardP: {
      fontSize: '14px',
      lineHeight: 1.6,
      color: '#b0b0b0',
      textAlign: 'justify',
    },
    highlightIcon: {
      fontSize: '14px',
      color: '#f97316',
      marginRight: '10px',
    },
    highlightSpan: {
      backgroundColor: '#f97316',
      color: '#ffffff',
      padding: '5px 10px',
      borderRadius: '20px',
      fontSize: '12px',
    },
    arrowIcon: {
      fontSize: '20px',
      color: '#f97316',
    },
  };

  const serviceData = [
    {
        icon: 'fas fa-cogs',
        title: 'Custom Software Development',
        description:
          'Develop tailored software solutions that meet the unique needs of your business. Our team crafts high-performance applications that streamline operations, enhance productivity, and drive growth across various industries.',
        highlights: [
          'Tailored Software Solutions',
          'Scalable and Robust Architecture',
          'Streamlined Business Operations',
          'Enhanced Productivity and Efficiency',
        ],
      },
      {
        icon: 'fas fa-laptop-code',
        title: 'Web Application Development',
        description:
          'Create dynamic and interactive web applications with advanced features and a seamless user experience. We use the latest technologies like React, Angular, and Node.js to build scalable and secure web-based solutions.',
        highlights: [
          'Responsive Web Applications',
          'Scalable and Secure Solutions',
          'Custom User Interfaces',
          'Seamless Integration with APIs',
        ],
      },
      {
        icon: 'fas fa-mobile-alt',
        title: 'Mobile Application Development',
        description:
          'Build mobile apps that engage users and provide smooth functionality across all devices. Whether you need iOS or Android apps, we offer full-stack mobile development services to create user-friendly, high-performing apps.',
        highlights: [
          'Cross-Platform Mobile Apps',
          'Native iOS and Android Development',
          'Intuitive User Interfaces',
          'High-Performance Mobile Solutions',
        ],
      },
      {
        icon: 'fas fa-database',
        title: 'Database Development and Management',
        description:
          'Create and manage robust databases that store, organize, and secure your data effectively. We specialize in SQL and NoSQL database technologies, ensuring optimal performance, scalability, and data integrity.',
        highlights: [
          'Database Design and Optimization',
          'SQL and NoSQL Solutions',
          'Data Integrity and Security',
          'Real-Time Data Processing',
        ],
      },
      {
        icon: 'fas fa-lock',
        title: 'Software Security Development',
        description:
          'Develop secure software that safeguards your business data and protects against potential cyber threats. We implement strong encryption, authentication protocols, and continuous monitoring to ensure your software is always secure.',
        highlights: [
          'End-to-End Security Implementation',
          'Data Encryption and Authentication',
          'Vulnerability Testing and Risk Mitigation',
          'Continuous Security Updates and Monitoring',
        ],
      },
      {
        icon: 'fas fa-cloud',
        title: 'Cloud-Based Software Solutions',
        description:
          'Deploy software solutions on the cloud to enhance accessibility, scalability, and performance. We utilize platforms like AWS, Microsoft Azure, and Google Cloud to create flexible and reliable software applications.',
        highlights: [
          'Cloud Hosting and Scalability',
          'Cost-Effective Cloud Solutions',
          'High Availability and Performance',
          'Seamless Integration with Cloud Services',
        ],
      },
      {
        icon: 'fas fa-robot',
        title: 'AI and Machine Learning Integration',
        description:
          'Incorporate AI and machine learning technologies into your software for enhanced decision-making, automation, and predictive analytics. We build intelligent systems that improve user experiences and operational efficiency.',
        highlights: [
          'AI-Powered Applications',
          'Machine Learning for Automation',
          'Predictive Analytics Integration',
          'Enhanced Decision-Making Processes',
        ],
      },
      {
        icon: 'fas fa-puzzle-piece',
        title: 'Software Integration and API Development',
        description:
          'Ensure seamless functionality across your systems with custom software integration and API development. We build robust APIs that connect your software solutions, allowing for smooth data exchange and system interoperability.',
        highlights: [
          'Custom API Development',
          'System Integration and Automation',
          'Data Exchange Between Platforms',
          'Improved Workflow and Efficiency',
        ],
      },
      {
        icon: 'fas fa-chart-line',
        title: 'Performance Optimization and Scalability',
        description:
          'Enhance the performance of your software with continuous optimization techniques. We focus on improving speed, reducing downtime, and ensuring your software scales effortlessly as your business grows.',
        highlights: [
          'Performance Tuning and Optimization',
          'Scalable Architecture',
          'Faster Load Times',
          'Reduced Latency and Downtime',
        ],
      },
      {
        icon: 'fas fa-sync',
        title: 'Software Maintenance and Support',
        description:
          'Keep your software up to date and running smoothly with our ongoing maintenance and support services. We provide regular updates, bug fixes, and troubleshooting to ensure your software remains functional and secure.',
        highlights: [
          'Regular Software Updates',
          'Bug Fixes and Troubleshooting',
          '24/7 Support Availability',
          'System Monitoring and Optimization',
        ],
      }
      
  ];

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div>
          <span style={styles.headerSpan}>SERVICES</span>
          <h1 style={styles.headerH1}>
            Our Expertise In Software Development
          </h1>
        </div>
        <div className="row g-4">
          {serviceData.map((service, index) => (
            <div className="col-12 col-md-6 col-lg-4" key={index}>
              <div
                style={styles.serviceCard}
                onMouseOver={(e) =>
                  Object.assign(e.currentTarget.style, styles.serviceCardHover)
                }
                onMouseOut={(e) =>
                  Object.assign(e.currentTarget.style, {
                    transform: 'translateY(0)',
                    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                  })
                }
              >
                <div className="text-center mb-3" style={styles.icon}>
                  <i className={service.icon}></i>
                </div>
                <h3 style={styles.serviceCardH3}>{service.title}</h3>
                <p style={styles.serviceCardP}>{service.description}</p>
                <div>
                  {service.highlights.map((highlight, idx) => (
                    <div key={idx} className="d-flex align-items-center mb-2">
                      <i
                        className="fas fa-check"
                        style={styles.highlightIcon}
                      ></i>
                      <span style={styles.highlightSpan}>{highlight}</span>
                    </div>
                  ))}
                </div>
                <div className="text-end">
                  <i className="fas fa-arrow-right" style={styles.arrowIcon}></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PageFiveSoftware;
