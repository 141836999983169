import React from 'react';
import servideo from '../imgvdo/servideo.gif';

const testimonials = [
  {
    category: 'Harrison',
    title: 'The app is visually appealing, performs seamlessly across different devices, and is free from bugs. Their attention to detail ensured that the app was not only functional but also engaging and intuitive for users.',
    location: 'UK',
    icon: '👍',
    image: 'https://images.pexels.com/photos/7947951/pexels-photo-7947951.jpeg',
    technology: 'ALL',
  },
  {
    category: 'Harry Bidwell',
    title: 'Kotibox Team demonstrated a deep understanding of ERP systems and tailored their approach to meet our specific needs. Their technical expertise was evident in the robust and scalable solution they provided.',
    location: 'USA',
    icon: '😳',
    image: 'https://images.pexels.com/photos/5668842/pexels-photo-5668842.jpeg',
    technology: 'ALL',
  },
  {
    category: 'Sylvie',
    title: 'The team at Kotibox Global Technologies was incredibly responsive and communicative throughout the entire process. They delivered a visually stunning website that was highly functional and user-friendly.',
    location: 'UK',
    icon: '😎',
    image: 'https://images.pexels.com/photos/3823488/pexels-photo-3823488.jpeg',
    technology: 'ALL',
  },
  {
    category: 'Amelia Robertson',
    title: 'Working with Kotibox was a delight. Their agile development process and regular updates made me feel involved every step of the way. I couldn’t be happier with the result!',
    location: 'Canada',
    icon: '🎉',
    image: 'https://images.pexels.com/photos/1239291/pexels-photo-1239291.jpeg',
    technology: 'ReactJS',
  },
  {
    category: 'David Thompson',
    title: 'I was impressed by their deep understanding of the latest technologies and their ability to implement them seamlessly. The platform they built for us is scalable, secure, and highly efficient.',
    location: 'Australia',
    icon: '💻',
    image: 'https://images.pexels.com/photos/3153201/pexels-photo-3153201.jpeg',
    technology: 'NodeJS',
  },
  {
    category: 'Sophia Lee',
    title: 'Kotibox transformed our outdated system into a modern and user-friendly solution. Their dedication and creativity ensured our project was a huge success!',
    location: 'Singapore',
    icon: '✨',
    image: 'https://images.pexels.com/photos/7176319/pexels-photo-7176319.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    technology: 'MERN',
  },
  {
    category: 'James Parker',
    title: 'The Kotibox team delivered an exceptional mobile app that not only meets our needs but exceeds our expectations. Their expertise in mobile development and user experience design is unmatched.',
    location: 'USA',
    icon: '📱',
    image: 'https://images.pexels.com/photos/5077049/pexels-photo-5077049.jpeg',
    technology: 'React Native',
  },
  {
    category: 'Lily Evans',
    title: 'Kotibox helped us redesign our website, and the result was a modern, intuitive, and responsive site. Their approach was collaborative, and they truly understood our vision.',
    location: 'UK',
    icon: '🌐',
    image: 'https://images.pexels.com/photos/4017899/pexels-photo-4017899.jpeg',
    technology: 'NextJS',
  },

];

const styles = {
  processContainer: {
    backgroundColor: '#111111',
    color: 'white',
    minHeight: '60vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
  processContent: {
    width: '90%',
    maxWidth: '1000px',
    margin: '0 auto',
    textAlign: 'left',
  },
  processTitle: {
    fontSize: '2rem',
    marginBottom: '1rem',
    fontWeight: 'bold',
  },
  processSubtitle: {
    fontSize: '1rem',
    marginBottom: '2rem',
    color: '#bbb',
  },
  processImage: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)',
  },
};

const Testimonials = () => {
  return (
    <>
      <div className="testimonial-container" style={{ padding: '40px', backgroundColor: '#111' }}>
        <h1 style={{ color: 'white', textAlign: 'center', marginBottom: '20px' }}>
          Client <span style={{ color: 'red' }}>Success Stories</span>
        </h1>
        <h5 style={{ color: 'yellow', textAlign: 'center', marginBottom: '40px' }}>
          Grow your business with a team that wants to see you win
        </h5>
        <div
          className="testimonial-grid"
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: '20px',
            justifyContent: 'center',
          }}
        >
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="testimonial-card"
              style={{
                backgroundColor: '#00000',
                padding: '15px',
                borderRadius: '10px',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.3)',
                textAlign: 'left',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              }}
            >
              <img
                src={testimonial.image}
                alt={testimonial.title}
                style={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                  borderRadius: '8px',
                  marginBottom: '10px',
                  transition: 'transform 0.3s ease',
                }}
                className="testimonial-image"
              />
              <div style={{ color: '#ff9900', fontWeight: 'bold' }}>{testimonial.category}</div>
              <div style={{ fontSize: '16px', color: 'white', margin: '10px 0' }}>
                {testimonial.title}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <span style={{ color: '#ccc' }}>{testimonial.location}</span>
                <span>{testimonial.icon}</span>
                <span style={{ color: '#fff' }}>{testimonial.technology}</span>
              </div>
            </div>
          ))}
        </div>
        <style>{`
          .testimonial-image:hover {
            transform: scale(1.05);
          }
          @media (max-width: 768px) {
            h1 {
              font-size: 24px;
            }
            h5 {
              font-size: 16px;
            }
          }
          @media (max-width: 480px) {
            .testimonial-container {
              padding: 20px;
            }
            h1 {
              font-size: 20px;
            }
            h5 {
              font-size: 14px;
            }
          }
        `}</style>
      </div>
      <div style={styles.processContainer}>
        <div style={styles.processContent}>
          <h1 style={styles.processTitle}>Our Process</h1>
          <h2 style={styles.processSubtitle}>
            Unlock your true potential, leverage the best of technology, and create applications based on a modern algorithm with our experts.
          </h2>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={servideo} // Replace this with your actual video or GIF URL
              alt="Demo GIF"
              style={styles.processImage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
