import React, { useEffect, useState } from 'react';
import './HomePage.css';
import webvideo from '../imgvdo/webvideo.mp4'; // Import the video file
import Service from './Service';
import About from './About';
import Industries from './Industries';
import TechnologyExpertise from './TechnologyExpertise';
import CompanyGallery from './CompanyGallery';
import Banner from './Banner';
import Testimonials from './Testimonials';
import ContactForm from './contact';

const HomePage = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const words = [ "Creative", "Efficient", "Tailored", "Scalable", "Astute"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000); // Change word every 3 seconds

    return () => clearInterval(interval);
  }, [words.length]);

  return (
    <>
      <div className="homepage">
        {/* Background Video */}
        <video className="background-video" autoPlay loop muted>
          <source src={webvideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Centered Text */}
        <div className="homepage-content  " >
          <h1 className="company-name">
            Transforming Ideas into <span className="highlighted-word">{words[currentWordIndex]}</span> Digital Solutions
          </h1>
          <p className="company-description ">
            Revolutionize your business with cutting-edge, next-Generation technology solutions. Let’s innovate, elevate and fuel your Growth!
          </p>
        </div>

        {/* What We Provide Section */}
        <div className="what-we-provide">
          <h2 className="section-heading">What We Provide</h2>
          <div className="buttons-container">
            {/* 8 Transparent Buttons */}
            <div className="buttons-row">
              <button className="transparent-button">Software Development</button>
              <button className="transparent-button">Mobile Apps Development</button>
              <button className="transparent-button">Web Development</button>
              <button className="transparent-button">E-Commerce Service</button>
            </div>
            <div className="buttons-row">
              <button className="transparent-button">Cloud Service</button>
              <button className="transparent-button">CRM Service</button>
              <button className="transparent-button">ERP Service</button>
              <button className="transparent-button">Salesforce Solution</button>
            </div>
          </div>
        </div>
      </div>

      <Service />
      <About />
      <Industries />
      <TechnologyExpertise />
      <CompanyGallery />
      <Banner />
      <Testimonials />
      <ContactForm />
    </>
  );
}

export default HomePage;
