import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faCogs, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import PageThreeChain from './PageThreeChain';
import PageFourChain from './PageFourChain';
import PageTwoContent from '../Content/PageTwoContent';
import PageTwoChain from './PageTwoChain';
import PagefiveChain from './PageFiveChain';



// Styled Components for responsiveness

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px;
  flex-wrap: wrap;
  gap: 20px;
  background-color: black;

  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const LeftSection = styled.div`
  max-width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 30px;
  color: white;
  margin-left: 5%;

  @media (max-width: 1200px) {
    max-width: 100%;
    margin-left: 0;
    padding: 20px;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const RightSection = styled.div`
  background-color: #333;
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  max-width: 48%;
  margin-right: 20vh;

  @media (max-width: 1200px) {
    max-width: 100%;
    margin-right: 0;
    padding: 20px;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const RightSectionHeading = styled.h3`
  color: #ff6600;
  font-size: 20px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const FeatureIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  margin-right: 8px;
  color: #ff6600;
`;

const SubmitBtn = styled.button`
  background-color: #ff6600;
  color: white;
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 13px;

  &:hover {
    background-color: #e65c00;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const Blockchain = ({ 
    heading1 = 'Website Development', 
    heading2 = 'It offers end-to-end solutions', 
    icon1 = faMobileAlt, 
    icon2 = faCogs, 
    icon3 = faSyncAlt ,
    label1 = 'Blockchain Solutions',
    label2 = 'Smart Contracts',
    label3 = 'Data Security'
}) => {

    return (
        <div style={{ backgroundColor: 'black', color: 'rgb(255, 255, 255)', margin: 0, fontFamily: 'Arial, sans-serif' }}>
            <Container>
                <LeftSection>
                    <h1>Blockchain</h1>
                    <h2>Blockchain Development Services</h2>
                    <div>
                        {[ 
                            { icon: icon1, label: label1 },
                            { icon: icon2, label: label2 },
                            { icon: icon3, label: label3 },
                        ].map((feature, index) => (
                            <FeatureItem key={index}>
                                <FeatureIcon icon={feature.icon} />
                                <span>{feature.label}</span>
                            </FeatureItem>
                        ))}
                    </div>
                </LeftSection>

                <RightSection>
                    <RightSectionHeading>Book Free Consultation</RightSectionHeading>
                    <p>Fill Out the Form and Our Experts Will Contact You Within 24hrs.</p>
                    <form>
                        {[ 
                            { label: "Full Name*", id: "full-name", type: "text", required: true },
                            { label: "Email*", id: "email", type: "email", required: true },
                            { label: "Phone Number*", id: "phone-number", type: "tel", required: true },
                            { label: "Company Name", id: "company-name", type: "text" },
                        ].map((field, index) => (
                            <div key={index}>
                                <label htmlFor={field.id}>{field.label}</label>
                                <input
                                    id={field.id}
                                    name={field.id}
                                    type={field.type}
                                    required={field.required}
                                    style={{ width: '100%', padding: '6px', border: 'none', borderRadius: '5px', marginTop: '4px', fontSize: '13px', backgroundColor: '#444', color: 'white' }}
                                />
                            </div>
                        ))}
                        <div>
                            <label htmlFor="requirement">Your Requirement*</label>
                            <textarea id="requirement" name="requirement" required style={{ width: '100%', height: '70px', padding: '6px', border: 'none', borderRadius: '5px', marginTop: '4px', fontSize: '13px', backgroundColor: '#444', color: 'white' }}></textarea>
                        </div>
                        <SubmitBtn type="submit">Submit Your Requirement!</SubmitBtn>
                    </form>
                </RightSection>
            </Container>

            
            <PageTwoChain/>
            <PagefiveChain/>
            <PageThreeChain/>
            <PageFourChain/>
            
           
           
            

        </div>
    );
}

export default Blockchain;
