import React from 'react';

const PagefiveArt = () => {
  const styles = {
    body: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: '#121212', // Dark mode background
      color: '#ffffff',
      padding: 0,
      margin: 0,
    },
    container: {
      padding: '50px 15px',
    },
    headerSpan: {
      color: '#f97316',
      fontSize: '14px',
      fontWeight: 700,
      textAlign: 'center',
      display: 'block',
      marginBottom: '10px',
    },
    headerH1: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: 1.3,
      textAlign: 'center',
      marginBottom: '30px',
    },
    serviceCard: {
      backgroundColor: '#1e1e1e', // Dark card background
      borderRadius: '12px',
      padding: '20px',
      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      margin: '0 10%', // 10% margin on left and right
      maxWidth: '80%', // Restrict card size
    },
    serviceCardHover: {
      transform: 'translateY(-10px)',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
    },
    icon: {
      fontSize: '40px',
      color: '#f97316',
    },
    serviceCardH3: {
      fontSize: '20px',
      fontWeight: 700,
      margin: '20px 0 10px',
      textAlign: 'center',
      color: '#ffffff',
    },
    serviceCardP: {
      fontSize: '14px',
      lineHeight: 1.6,
      color: '#b0b0b0',
      textAlign: 'justify',
    },
    highlightIcon: {
      fontSize: '14px',
      color: '#f97316',
      marginRight: '10px',
    },
    highlightSpan: {
      backgroundColor: '#f97316',
      color: '#ffffff',
      padding: '5px 10px',
      borderRadius: '20px',
      fontSize: '12px',
    },
    arrowIcon: {
      fontSize: '20px',
      color: '#f97316',
    },
  };

  const serviceData = [
    {
      icon: 'fas fa-brain',
      title: 'Artificial Intelligence Solutions',
      description:
        'Harness the power of AI to drive innovation in your business. From machine learning models to natural language processing, we create intelligent systems that adapt, learn, and make decisions to optimize your processes and improve customer experiences.',
      highlights: [
        'Data-Driven Decision Making',
        'Enhanced Automation and Efficiency',
        'Predictive Analytics and Insights',
        'Personalized User Experiences',
      ],
    },
    {
      icon: 'fas fa-cogs',
      title: 'Machine Learning Development',
      description:
        'Unlock the potential of machine learning algorithms to predict trends, enhance data analysis, and improve decision-making processes. We build custom ML models to help you solve complex problems and improve operational efficiency.',
      highlights: [
        'Custom ML Models',
        'Advanced Predictive Analytics',
        'Real-Time Data Processing',
        'Continuous Learning and Improvement',
      ],
    },
    {
      icon: 'fas fa-comments',
      title: 'Natural Language Processing (NLP)',
      description:
        'Empower your business with the ability to understand and process human language. From chatbots to sentiment analysis, our NLP solutions enhance communication and automate customer interactions with advanced AI-driven technologies.',
      highlights: [
        'Chatbots and Virtual Assistants',
        'Sentiment and Emotion Analysis',
        'Text-to-Speech and Speech-to-Text',
        'Language Translation Services',
      ],
    },
    {
      icon: 'fas fa-robot',
      title: 'AI-Powered Automation',
      description:
        'Streamline your workflows and increase productivity with AI-driven automation. We create intelligent systems that automate repetitive tasks, reduce errors, and enhance operational efficiency, giving your business more time to focus on growth.',
      highlights: [
        'Process Automation',
        'Task Scheduling and Optimization',
        'Intelligent Workflow Management',
        'Error Reduction and Accuracy',
      ],
    },
    {
      icon: 'fas fa-palette',
      title: 'AI-Powered Visual Recognition',
      description:
        'Enhance your applications with AI-based image and video recognition. Whether its facial recognition object detection, or content moderation, we build intelligent systems that can interpret visual data to add value to your products and services.',
      highlights: [
        'Facial and Object Recognition',
        'Real-Time Visual Analysis',
        'Automated Content Moderation',
        'Advanced Image and Video Classification',
      ],
    },
    {
      icon: 'fas fa-database',
      title: 'AI and Big Data Analytics',
      description:
        'Transform large sets of data into actionable insights with AI-driven analytics. Our solutions allow businesses to harness the power of big data, discovering patterns and trends that lead to smarter business decisions.',
      highlights: [
        'Data Processing and Visualization',
        'Predictive Data Analytics',
        'Advanced Reporting and Dashboards',
        'Real-Time Big Data Insights',
      ],
    },
    {
      icon: 'fas fa-clipboard-check',
      title: 'AI-Driven Decision Support Systems',
      description:
        'Empower your business with AI-powered decision support tools that provide intelligent recommendations and insights. These systems help in evaluating options, predicting outcomes, and guiding business strategies.',
      highlights: [
        'Data-Driven Decision Making',
        'Scenario Analysis and Forecasting',
        'Real-Time Recommendations',
        'Improved Business Strategy and Planning',
      ],
    },
    {
      icon: 'fas fa-headset',
      title: 'AI-Powered Customer Support',
      description:
        'Leverage AI to enhance your customer service experience. With AI chatbots and virtual assistants, we automate responses, predict customer needs, and provide personalized support to boost satisfaction and engagement.',
      highlights: [
        'Automated Customer Support',
        '24/7 Availability',
        'Personalized Interactions',
        'Seamless Integration with CRM Systems',
      ],
    },
    {
      icon: 'fas fa-cogs',
      title: 'AI for Predictive Maintenance',
      description:
        'Utilize AI to predict equipment failures before they happen. Our predictive maintenance solutions use real-time data and machine learning algorithms to minimize downtime and reduce maintenance costs for your business.',
      highlights: [
        'Real-Time Monitoring and Alerts',
        'Predictive Maintenance Models',
        'Reduced Equipment Downtime',
        'Cost Savings on Maintenance',
      ],
    }
    
  ];

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div>
          <span style={styles.headerSpan}>SERVICES</span>
          <h1 style={styles.headerH1}>
            Our Expertise In Mobile Artificial Intelligence
          </h1>
        </div>
        <div className="row g-4">
          {serviceData.map((service, index) => (
            <div className="col-12 col-md-6 col-lg-4" key={index}>
              <div
                style={styles.serviceCard}
                onMouseOver={(e) =>
                  Object.assign(e.currentTarget.style, styles.serviceCardHover)
                }
                onMouseOut={(e) =>
                  Object.assign(e.currentTarget.style, {
                    transform: 'translateY(0)',
                    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                  })
                }
              >
                <div className="text-center mb-3" style={styles.icon}>
                  <i className={service.icon}></i>
                </div>
                <h3 style={styles.serviceCardH3}>{service.title}</h3>
                <p style={styles.serviceCardP}>{service.description}</p>
                <div>
                  {service.highlights.map((highlight, idx) => (
                    <div key={idx} className="d-flex align-items-center mb-2">
                      <i
                        className="fas fa-check"
                        style={styles.highlightIcon}
                      ></i>
                      <span style={styles.highlightSpan}>{highlight}</span>
                    </div>
                  ))}
                </div>
                <div className="text-end">
                  <i className="fas fa-arrow-right" style={styles.arrowIcon}></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PagefiveArt;
