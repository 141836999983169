import React from 'react';

const PagefiveChain = () => {
  const styles = {
    body: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: '#121212', // Dark mode background
      color: '#ffffff',
      padding: 0,
      margin: 0,
    },
    container: {
      padding: '50px 15px',
    },
    headerSpan: {
      color: '#f97316',
      fontSize: '14px',
      fontWeight: 700,
      textAlign: 'center',
      display: 'block',
      marginBottom: '10px',
    },
    headerH1: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: 1.3,
      textAlign: 'center',
      marginBottom: '30px',
    },
    serviceCard: {
      backgroundColor: '#1e1e1e', // Dark card background
      borderRadius: '12px',
      padding: '20px',
      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      margin: '0 10%', // 10% margin on left and right
      maxWidth: '80%', // Restrict card size
    },
    serviceCardHover: {
      transform: 'translateY(-10px)',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
    },
    icon: {
      fontSize: '40px',
      color: '#f97316',
    },
    serviceCardH3: {
      fontSize: '20px',
      fontWeight: 700,
      margin: '20px 0 10px',
      textAlign: 'center',
      color: '#ffffff',
    },
    serviceCardP: {
      fontSize: '14px',
      lineHeight: 1.6,
      color: '#b0b0b0',
      textAlign: 'justify',
    },
    highlightIcon: {
      fontSize: '14px',
      color: '#f97316',
      marginRight: '10px',
    },
    highlightSpan: {
      backgroundColor: '#f97316',
      color: '#ffffff',
      padding: '5px 10px',
      borderRadius: '20px',
      fontSize: '12px',
    },
    arrowIcon: {
      fontSize: '20px',
      color: '#f97316',
    },
  };

  const serviceData = [
    {
        icon: 'fas fa-link',
        title: 'Blockchain Development',
        description:
          'Unlock the potential of decentralized systems with our Blockchain development services. We create secure, transparent, and efficient solutions using blockchain technology to transform industries such as finance, supply chain, and healthcare.',
        highlights: [
          'Decentralized Data Security',
          'Transparent Transactions',
          'Immutable Records',
          'Smart Contract Automation',
        ],
      },
      {
        icon: 'fas fa-credit-card',
        title: 'Cryptocurrency Solutions',
        description:
          'Get involved in the future of digital finance with our cryptocurrency solutions. From wallet development to token creation and secure transactions, we provide full-cycle support to help you navigate the cryptocurrency ecosystem.',
        highlights: [
          'Secure Wallet Development',
          'Token Creation and ICO Support',
          'Cryptocurrency Payment Integration',
          'Blockchain-based Financial Solutions',
        ],
      },
      {
        icon: 'fas fa-file-alt',
        title: 'Smart Contracts Development',
        description:
          'Automate business agreements and transactions with smart contracts. We develop decentralized, self-executing contracts that ensure trust, security, and efficiency without intermediaries, minimizing risks and improving transparency.',
        highlights: [
          'Self-Executing Agreements',
          'No Intermediaries',
          'Increased Efficiency and Trust',
          'Automated Transaction Execution',
        ],
      },
      {
        icon: 'fas fa-puzzle-piece',
        title: 'Blockchain Integration Services',
        description:
          'Seamlessly integrate blockchain technology into your existing systems. Our team works with you to enhance your current infrastructure by adding blockchain-based features that improve security, traceability, and operational efficiency.',
        highlights: [
          'Seamless System Integration',
          'Enhanced Security and Transparency',
          'Smart Contract Integration',
          'Supply Chain Optimization',
        ],
      },
      {
        icon: 'fas fa-box-open',
        title: 'Supply Chain Blockchain Solutions',
        description:
          'Revolutionize your supply chain with blockchain technology. Track products from origin to delivery with immutable, transparent records, reducing fraud, improving traceability, and streamlining logistics.',
        highlights: [
          'End-to-End Product Tracking',
          'Increased Transparency',
          'Improved Supply Chain Efficiency',
          'Enhanced Fraud Prevention',
        ],
      },
      {
        icon: 'fas fa-shield-alt',
        title: 'Blockchain Security Solutions',
        description:
          'Protect your business from cyber threats with blockchain-based security solutions. We offer secure, tamper-proof systems that ensure data integrity, protect privacy, and prevent fraud.',
        highlights: [
          'Tamper-Proof Security',
          'Data Integrity and Privacy Protection',
          'Decentralized Risk Management',
          'Fraud Prevention Systems',
        ],
      },
      {
        icon: 'fas fa-users',
        title: 'Decentralized Application (DApp) Development',
        description:
          'Build next-generation decentralized applications (DApps) using blockchain technology. We develop scalable, secure, and efficient DApps that run on decentralized networks, providing transparency and eliminating single points of failure.',
        highlights: [
          'Scalable DApp Development',
          'Enhanced Security with Blockchain',
          'Transparent and Decentralized',
          'Peer-to-Peer Interaction',
        ],
      },
      {
        icon: 'fas fa-hands-helping',
        title: 'Blockchain for Nonprofits',
        description:
          'Leverage blockchain to enhance transparency, traceability, and accountability for nonprofit organizations. Our solutions help track donations, ensure fund allocation, and provide donors with transparent reports.',
        highlights: [
          'Transparent Donation Tracking',
          'Enhanced Accountability',
          'Improved Trust and Engagement',
          'Decentralized Fund Management',
        ],
      },
      {
        icon: 'fas fa-chart-line',
        title: 'Blockchain for Financial Services',
        description:
          'Transform the financial sector with blockchain solutions. We help streamline transactions, enhance security, and create decentralized financial products that drive innovation in payments, lending, and asset management.',
        highlights: [
          'Decentralized Payments and Transactions',
          'Blockchain-based Lending Solutions',
          'Improved Asset Management',
          'Enhanced Financial Transparency',
        ],
      }
      
    
  ];

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div>
          <span style={styles.headerSpan}>SERVICES</span>
          <h1 style={styles.headerH1}>
            Our Expertise In Blockchain Services
          </h1>
        </div>
        <div className="row g-4">
          {serviceData.map((service, index) => (
            <div className="col-12 col-md-6 col-lg-4" key={index}>
              <div
                style={styles.serviceCard}
                onMouseOver={(e) =>
                  Object.assign(e.currentTarget.style, styles.serviceCardHover)
                }
                onMouseOut={(e) =>
                  Object.assign(e.currentTarget.style, {
                    transform: 'translateY(0)',
                    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                  })
                }
              >
                <div className="text-center mb-3" style={styles.icon}>
                  <i className={service.icon}></i>
                </div>
                <h3 style={styles.serviceCardH3}>{service.title}</h3>
                <p style={styles.serviceCardP}>{service.description}</p>
                <div>
                  {service.highlights.map((highlight, idx) => (
                    <div key={idx} className="d-flex align-items-center mb-2">
                      <i
                        className="fas fa-check"
                        style={styles.highlightIcon}
                      ></i>
                      <span style={styles.highlightSpan}>{highlight}</span>
                    </div>
                  ))}
                </div>
                <div className="text-end">
                  <i className="fas fa-arrow-right" style={styles.arrowIcon}></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PagefiveChain;
